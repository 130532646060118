import axios from 'axios'

const state = {
  name: null,
  lat: null,
  lon: null,
  password: null,
  address: null,
}

const getters = {
  keyStationName: (state) => {
    return state.name
  },
  keyStationLat: (state) => {
    return state.lat
  },
  keyStationLon: (state) => {
    return state.lon
  },
  keyStationPassword: (state) => {
    return state.password
  },
  keyStationAddress: (state) => {
    return state.address
  },
}

const mutations = {
  setName(state, payload) {
    state.name = payload
  },
  setLat(state, payload) {
    state.lat = payload
  },
  setLon(state, payload) {
    state.lon = payload
  },
  setPassword(state, payload) {
    state.password = payload
  },
  setAddress(state, payload) {
    state.address = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}


