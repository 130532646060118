<template>
  <div class='container mb-3'>
    <div class='row'>
      <div class='col-12 w80 m0a h400 scrollable bwhite fs14 pt-3 pb-3'>
        <p>・発行される内見用のナビゲーションに従い、お客様のみで現地の内見を行って頂く方法です。<br />
          その為、仲介会社様が現地に行かずとも内見のご案内が可能となります。</p>
        <p>・お客様にはこの後発行されますナビゲーション用のURLをお渡しください</p>
        <p>・その他詳しい内見の流れはこの後表示される資料にまとめておりますので、ご確認ください。</p>
      </div>
    </div>
    <div class='row w80 m0a mt-4'>
      <div class='col-6 text-center pl-0'>
        <button class='btn btn-lg btn-block btn-rounded btn-secondary' @click="onStepSelected('top')">
          <i class="fas fa-backward"></i>
            別の方法にする
        </button>
      </div>
      <div class='col-6 text-center pr-0'>
        <button class='btn btn-lg btn-block btn-rounded btn-success' @click="showCautionModal()">
          遠隔案内にする
          <i class="fas fa-forward"></i>
        </button>
        <caution-modal @onStepSelected="onStepSelected" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import cautionModal from '@/components/company/RemoteCautionModal.vue'
export default {
  name: 'LocalGuidanceIntroduction',
  components: { cautionModal },
  data() {
    return {
    }
  },
  async mounted() {
  },
  computed: {
  },
  methods: {
    onStepSelected(step){
      this.$emit('onStepSelected', step)
    },
    showCautionModal(){
      this.$modal.show('remote-caution-modal')
    }
  },
  watch: {
  }
}
</script>

<style scoped>
.w80{
  width: 80% !important
}
.h400{
  height: 300px !important
}
.m0a{
  margin: 0 auto;
}
.scrollable {
  overflow: scroll;
}
.bwhite {
  background: white;
}
</style>
