import Vue from 'vue'
import Vuex from 'vuex'
import flow from '@/store/modules/flow'
import flowDetail from '@/store/modules/flowDetail'
import flowDetails from '@/store/modules/flowDetails'
import reservation from '@/store/modules/reservation'
import customer from '@/store/modules/customer'
import user from '@/store/modules/user'
import company from '@/store/modules/company'
import keyStation from '@/store/modules/keyStation'
import doorJoiner from '@/store/modules/doorJoiner'
import property from '@/store/modules/property'
import room from '@/store/modules/room'

Vue.use(Vuex)

const store =  new Vuex.Store({
  modules: {
    flow,
    flowDetail,
    flowDetails,
    reservation,
    customer,
    user,
    company,
    keyStation,
    doorJoiner,
    property,
    room
  }
})

export default store
