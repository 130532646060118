import axios from 'axios'

const state = {
  id: null,
  step: null,
  token: null,
  flowId: null,
  startAt: null,
  roomWithProperty: null,
}

const getters = {
  reservationId: (state) => {
    return state.id
  },
  reservationStep: (state) => {
    return state.step
  },
  reservationToken: (state) => {
    return state.token
  },
  reservationStartAt: (state) => {
    return state.startAt
  },
  reservationRoomWithProperty: (state) => {
    return state.roomWithProperty
  },
}

const mutations = {
  setId(state, payload) {
    state.id = payload
  },
  setStep(state, payload) {
    state.step = payload
  },
  setToken(state, payload) {
    state.token = payload
  },
  setStartAt(state, payload) {
    state.startAt = payload
  },
  setRoomWithProperty(state, payload) {
    state.roomWithProperty = payload
  },
}

const actions = {
  updateStep: function(context, payload) {
    context.commit('setStep', payload)
  },
  updateToken: function(context, payload) {
    context.commit('setToken', payload)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

