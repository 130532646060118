import axios from 'axios'

const state = {
  password: null,
  imageUrl: null,
}

const getters = {
  doorJoinerPassword: (state) => {
    return state.password
  },
  doorJoinerImageUrl: (state) => {
    return state.imageUrl
  },
}

const mutations = {
  setPassword(state, payload) {
    state.password = payload
  },
  setImageUrl(state, payload) {
    state.imageUrl = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}



