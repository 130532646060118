<template>
  <div class="container">
    <vue-loading type="spin" color="#81CB2B" :size="{ width: '100px', height: '100px' }"></vue-loading>
  </div>
</template>

<script>
import { VueLoading } from 'vue-loading-template'
export default {
  name: 'Loading',
  components: { VueLoading },
  data() {
    return {}
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped>
.container {
  position: relative;
  height: 100%;
}
.vue-loading {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  margin-top: 300px;
}
</style>

