import axios from 'axios'

const state = {
  name: null,
  address: null,
  station: null,
  stationLine: null,
  lat: null,
  lon: null,
  useFaceVerification: null,
}

const getters = {
  propertyName: (state) => {
    return state.name
  },
  propertyAddress: (state) => {
    return state.address
  },
  propertyStation: (state) => {
    return state.station
  },
  propertyStationLine: (state) => {
    return state.stationLine
  },
  propertyLat: (state) => {
    return state.lat
  },
  propertyLon: (state) => {
    return state.lon
  },
  propertyUseFaceVerification: (state) => {
    return state.useFaceVerification
  },
}

const mutations = {
  setName(state, payload) {
    state.name = payload
  },
  setAddress(state, payload) {
    state.address = payload
  },
  setStation(state, payload) {
    state.station = payload
  },
  setStationLine(state, payload) {
    state.stationLine = payload
  },
  setLat(state, payload) {
    state.lat = payload
  },
  setLon(state, payload) {
    state.lon = payload
  },
  setUseFaceVerification(state, payload) {
    state.useFaceVerification = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
