<template>
  <div id='flowForm'>
    <div class="row">
      <div class="alert alert-danger alert-rounded w100" v-if="!errorMsg == ''">
        {{ errorMsg }}
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">
              <span>内見フロー</span>
              <span class='text-info addFlowDetailBtn float-right'
                @click="addFlowDetail()">
                <i class="fas fa-plus-circle"></i>
              </span>
            </h4>
            <draggable
              v-model="draggable.flowDetails"
              element="ul"
              class='list-group'
              :options="{ animation: 300 }"
              @choose="onItemSelected($event)"
              @end="onOrderChanged($event)">
              <li v-for="flowDetail in draggable.flowDetails" :key="flowDetail.id" class="list-group-item mt-2">
                {{flowDetail.order}}. {{flowDetailListTitle(flowDetail)}}
                <a href="javascript:void(0)"
                  class='float-right'
                  @click="removeFlowDetail(flowDetail.id)">
                <span class='text-danger float-right'>
                  <i class="fas fa-trash"></i>
                </span>
                </a>
              </li>
            </draggable>
          </div>
        </div>
        <!-- スマホでどう見えるかは時間とやる気があったら作る -->
        <div id='sp_flow_image' v-if=false>
          <img id='sp_flow_image' src='~smart_phone.png' />
          <div>
            <p>{{flowDetailTitle}}</p>
            <p>{{flowDetailSubTitle}}</p>
            <p>{{flowDetailText}}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">
              フロー詳細
              <span class='fs075 text-color' v-show="selectedDetailOrder.length != 0">(No.{{selectedDetailOrder}})</span>
            </h4>
            <flow-detail-form
              @save="fetchFlow"
              v-show="detailFormShowable" />
          </div>
        </div>
        <flow-notes-form  />
      </div>
    </div>
    <div class='row'>
      <div class='w100 mb-3 mt-5'>
        <button
          class="btn btn-sm btn-danger d-lg-block float-right mr15"
          @click="deleteFlow()">フロー削除
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import axios from "axios"
import FlowDetailForm from "@/components/FlowDetailForm"
import FlowNotesForm from "@/components/FlowNotesForm"
import { mapGetters } from 'vuex'
export default {
  name: 'FlowForm',
  components: { draggable, FlowDetailForm, FlowNotesForm },
  data() {
    return {
      draggable: {
        flowDetails: []
      },
      selectedDetailOrder: "",
      detailFormShowable: false,
      errorMsg: '',
    }
  },
  async mounted() {
    this.$store.commit('flow/setId', window.location.href.match(/flows\/([0-9]+)/)[1])
    await this.fetchFlow()
  },
  computed: {
    ...mapGetters('flow', ['flowId']),
    ...mapGetters('flowDetail', ['flowDetailId', 'flowDetailTitle', 'flowDetailSubTitle', 'flowDetailText', 'flowDetailFormat']),
    flowDetailListTitle: function(){
      return function(flowDetail){
        if(flowDetail.format == 'basic'){
          return flowDetail.title
        } else if(flowDetail.format == 'get_key_from_ks') {
          return '鍵を入手する(KEY STATION)'
        } else if(flowDetail.format == 'get_key_from_keybox') {
          return '鍵を入手する(キーボックス)'
        } else if(flowDetail.format == 'get_key_from_shop') {
          return '鍵を入手する(不動産屋店舗)'
        } else if(flowDetail.format == 'get_key_from_populife') {
          return '鍵を入手する(Populife)'
        } else if(flowDetail.format == 'unlock_door_joiner') {
          return 'ドアジョイナーを解除する'
        } else if(flowDetail.format == 'move_to_property') {
          return '物件に向かう'
        } else if(flowDetail.format == 'identification') {
          return '本人確認をする'
        } else if(flowDetail.format == 'call') {
          return '電話する'
        } else if(flowDetail.format == 'viewing') {
          return '内見する'
        } else if(flowDetail.format == 'return_key_to_ks') {
          return '鍵を返却する(KEY STATION)'
        } else if(flowDetail.format == 'return_key_to_keybox') {
          return '鍵を返却する(キーボックス)'
        } else if(flowDetail.format == 'return_key_to_shop') {
          return '鍵を返却する(不動産屋店舗)'
        } else if(flowDetail.format == 'return_key_to_populife') {
          return '鍵を返却する(Populife)'
        } else if(flowDetail.format == 'lock_door_joiner') {
          return 'ドアジョイナーを施錠する'
        } else if(flowDetail.format == 'finish_viewing') {
          return '内見を終了する'
        } else if(flowDetail.format == 'heading_to_ks') {
          return 'KEY STATIONに向かう'
        }
      }
    }
  },
  methods: {
    async fetchFlow() {
      try {
        const res = await axios.get(`/api/company/flows/${this.flowId}`);
        this.$store.commit('flow/setNotes', res.data.flow.notes)
        this.draggable.flowDetails = res.data.flow.flow_details
      } catch {
        alert('ERROR');
      }
    },
    onItemSelected(e) {
      this.detailFormShowable = true
      const fd = this.draggable.flowDetails[e.oldIndex]
      this.$store.commit('flowDetail/setId', fd.id)
      this.$store.commit('flowDetail/setTitle', fd.title)
      this.$store.commit('flowDetail/setSubTitle', fd.sub_title)
      this.$store.commit('flowDetail/setText', fd.text)
      this.$store.commit('flowDetail/setFormat', fd.format)
      this.$store.commit('flowDetail/setImage1', fd.image1)
      this.$store.commit('flowDetail/setImage2', fd.image2)
      this.selectedDetailOrder = e.oldIndex + 1
      $('.list-group-item').removeClass('active');
      $('.list-group-item').eq(e.oldIndex).addClass('active')
    },
    async onOrderChanged(e) {
      this.selectedDetailOrder = e.newIndex + 1
      try {
        const res = await axios.patch(`/api/company/flows/${this.flowId}`, {
          flowDetails: this.draggable.flowDetails
        });
        if(res.data.error != ''){
          this.errorMsg = res.data.error
          setTimeout(() => {
            this.errorMsg = '' }
            , 3000
          )
        }
      } catch(e) {
        this.errorMsg = 'エラーが発生したため、フローを更新できませんでした。'
      }
      this.fetchFlow()
      setTimeout(() => {
        this.errorMsg = '' }
        , 3000
      )
    },
    async addFlowDetail() {
      try {
        const res = await axios.post(`/api/company/flows/${this.flowId}/flow_details`);
        this.fetchFlow()
      } catch {
        alert('ERROR');
      }
    },
    async removeFlowDetail(id) {
      try {
        const res = await axios.delete(`/api/company/flows/${this.flowId}/flow_details/${id}`);
        if(res.data.error == ''){
          this.detailFormShowable = false
          this.selectedDetailOrder = ""
          this.$store.commit('flowDetail/setId', null)
          this.$store.commit('flowDetail/setTitle', null)
          this.$store.commit('flowDetail/setSubTitle', null)
          this.$store.commit('flowDetail/setText', null)
          this.fetchFlow()
        }else{
          this.errorMsg = res.data.error
          setTimeout(() => {
            this.errorMsg = '' }
            , 3000
          )
        }
      } catch {
        alert('ERROR');
      }
    },
    async deleteFlow(event) {
      var result = window.confirm("フローを削除します。よろしいですか？")
      if(result){
        const res = await axios.delete(`/company/flows/${this.flowId}`)
        location.href = `/company/flows/${this.flowId}/redirect_with_flash_message`
      }
    },

  },
}
</script>

<style scoped>
li {
  cursor: grab;
}
.addFlowDetailBtn{
  cursor: pointer;
}
.list-group-item + .list-group-item {
  border-top-width: thin;
}
#sp_flow_image {
  position: relative;
  width: 300px;
  margin-bottom: 15px;
}
#sp_flow_image img {
  width: 100%;
}
#sp_flow_image div {
  position: absolute;
  border: 1px solid #444;
  width: 48%;
  height: 71%;
  top: 10%;
  left: 26%;
  overflow: auth;
  background-color: white;
}
.list-group-item.active {
  color: #333333;
  background-color: white;
  border-color: #03a9f3;
}
</style>
