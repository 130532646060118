<template>
  <div class='container'>
    <div class='text-center'>
      <p class='welcome-title'>案内方法を選択してください</p>
    </div>
    <div class='text-center mt-5'>
      <div class='row'>
        <div class='col-md-6 text-center'>
          <div class="card clickable" @click="onStepSelected('local')">
            <div class="card-body top-selection">
              <h4 class="card-title top-selection-title">
                <i class="fas fa-person-booth"></i>
                現地案内
              </h4>
              <span class='text-color'>従来通り、お客様と現地で待ち合わせをしてご案内をする方法</span>
            </div>
          </div>
        </div>
        <div class='col-md-6 text-center'>
          <div class="card clickable" @click="onStepSelected('remote')">
            <div class="card-body top-selection">
              <h4 class="card-title top-selection-title">
                <i class="fas fa-laptop-house"></i>
                遠隔案内
              </h4>
              <span class='text-color'>いつでも内見の機能を利用し、遠隔で現地までのガイド、鍵の受け渡し、ご案内を行う方法</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'SelectGuidanceWay',
  data() {
    return {
    }
  },
  async mounted() {
  },
  computed: {
  },
  methods: {
    onStepSelected(step){
      this.$emit('onStepSelected', step)
    }
  },
  watch: {
  }
}
</script>

<style scoped>
.welcome-title {
  font-size: 40px;
  font-weight: bold;
  padding-top: 50px;
  color: black;
}
.top-selection {
  height: 200px;
  background-color: #EEE;
  background-color: white;
}
.top-selection-title {
  color: #444;
  font-size: 28px;
  font-weight: bold;
  padding-top: 60px;
}
.clickable {
  cursor: pointer;
}
</style>


