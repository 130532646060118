import axios from 'axios'

const state = {
  id: null,
  title: null,
  subTitle: null,
  text: null,
  format: null,
  image1: null,
  image2: null,
  proceedable: true
}

const getters = {
  flowDetailId: (state) => {
    return state.id
  },
  flowDetailTitle: (state) => {
    return state.title
  },
  flowDetailSubTitle: (state) => {
    return state.subTitle
  },
  flowDetailText: (state) => {
    return state.text
  },
  flowDetailFormat: (state) => {
    return state.format
  },
  flowDetailImage1: (state) => {
    return state.image1
  },
  flowDetailImage2: (state) => {
    return state.image2
  },
  flowDetailProceedable: (state) => {
    return state.proceedable
  },
}

const mutations = {
  setId(state, payload) {
    state.id = payload
  },
  setTitle(state, payload) {
    state.title = payload
  },
  setSubTitle(state, payload) {
    state.subTitle = payload
  },
  setText(state, payload) {
    state.text = payload
  },
  setFormat(state, payload) {
    state.format = payload
  },
  setImage1(state, payload) {
    state.image1 = payload
  },
  setImage2(state, payload) {
    state.image2 = payload
  },
  setProceedable(state, payload) {
    state.proceedable = payload
  },
}

const actions = {
  updateTitle: function(context, payload) {
    context.commit('setTitle', payload)
  },
  updateSubTitle: function(context) {
    context.commit('setSubTitle')
  },
  updateText: function(context) {
    context.commit('setText')
  },
  updateFlowDetailFormat: function(context) {
    context.commit('setFormat')
  },
}


export default {
  namespaced: true,
  getters,
  mutations,
  state,
}
