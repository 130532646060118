import axios from 'axios'

const state = {
  all: []
}

const getters = {
  flowDetailsAll: (state) => {
    return state.all
  },
}

const mutations = {
  setAll(state, payload) {
    state.all = payload
  },
}

export default {
  namespaced: true,
  getters,
  mutations,
  state,
}

