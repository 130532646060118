<template>
  <div id='reservationCalendarByRoom'>
    <div class='card'>
      <div class='row'>
        <div class='col-md-12'>
          <div class='card-body'>
            <FullCalendar :options="calendarOptions"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
export default {
  name: 'reservationCalenarByRoom',
  components: { FullCalendar },
  data() {
    return {
      propertyId: '',
      roomId: '',
      calendarOptions: {
        locale: 'ja',
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        selectable: true,
        dateClick: this.dateClicked,
        initialDate: this.selectedDateFromParams(),
        validRange: {
          start: moment(new Date).subtract(1, 'months').format("YYYY-MM-DD"),
          end: moment(new Date).add(3, 'months').format("YYYY-MM-DD"),
        },
        headerToolbar: {
          left: 'title',
          center: '',
          right: 'prev next dayGridMonth,dayGridWeek,timeGridDay'
        },
        events: []
      }
    }
  },
  async mounted() {
    this.propertyId = window.location.href.match(/properties\/(.+)\/rooms/)[1]
    this.roomId = window.location.href.match(/rooms\/(.+)\/reservations/)[1]
    this.fetchReservationsForCalendar()
  },
  computed: {
  },
  methods: {
    selectedDateFromParams() {
      return !(this.getParam('selectedDate') == null) ? moment(this.getParam('selectedDate')).format("YYYY-MM-DD") : moment(new Date).format("YYYY-MM-DD")
    },
    getParam(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    async fetchReservationsForCalendar() {
      try {
        const res = await axios.get(`/api/company/properties/${this.propertyId}/rooms/${this.roomId}/reservations/for_calendar`);
        this.calendarOptions['events'] = res.data.reservations
      } catch {
        alert('ERROR');
      }
    },

  }
}
</script>

<style scoped>
</style>
