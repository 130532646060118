<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">
        内見時注意事項
      </h4>
      <form class="m-t-40" role="form" accept-charset="UTF-8" @submit.prevent>
        <div class="alert alert-success alert-rounded" v-show="successFlashShowable">
          更新しました
        </div>
        <div class="alert alert-danger alert-rounded" v-show="failedFlashShowable">
          更新時にエラーが発生しました
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <textarea
                class="form-control form-control without-shadow"
                :value="flowNotes"
                rows="5"
                @input="onNotesChanged($event.target.value)">
              </textarea>
              <span class="bar"></span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group m-b-5 mt-2 w100 pr-2">
            <button name="button" type="submit" class="btn btn-info d-lg-block float-right" @click="onFormSubmit()">
              <i class="fas fa-plus"></i>
              登録
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
  name: 'FlowNotesForm',
  data() {
    return {
      successFlashShowable: false,
      failedFlashShowable: false,
    }
  },
  computed: {
    ...mapGetters('flow', ['flowId', 'flowNotes']),
  },
  methods: {
    addSuccessFlash() {
      this.successFlashShowable = true
      setTimeout(() => {
        this.successFlashShowable = false }
        , 2000
      )
    },
    addFailedFlash() {
      this.failedFlashShowable = true
      setTimeout(() => {
        this.failedFlashShowable = false }
        , 2000
      )
    },
    onNotesChanged(val) {
      this.$store.commit('flow/setNotes', val)
    },
    async onFormSubmit() {
      const url = `/api/company/flows/${this.flowId}`
      const res = await axios.patch(url,
        { flow: { notes: this.flowNotes }}
      )
      //TODO: 失敗の場合を考慮
      this.addSuccessFlash()
    },
  },
}
</script>

<style scoped>
</style>


