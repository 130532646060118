import Vue from 'vue'
import Vuex from 'vuex'
import Schedule from '@/pages/company/home/index'
import ReservationCalendar from '@/pages/company/reservations/index'
import FlowForm from '@/pages/company/flows/form'
import GuidanceWayForm from '@/pages/company/reservations/guidance_way_form'
import CustomerInfoForm from '@/components/CustomerInfoForm'
import ReservationOpinionForm from '@/components/ReservationOpinionForm'
import TakeFaceImageModal from '@/components/company/TakeFaceImageModal'
import TakeCollatingPhoto from '@/components/company/TakeCollatingPhoto'
import PropertyKsForm from '@/pages/company/property/edit'
import ReservationCalendarByRoom from '@/pages/company/reservations/index_by_room'
import VCalendar from 'v-calendar'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import FullCalendarInteraction from '@fullcalendar/interaction'
import VModal from 'vue-js-modal'
import axios from 'axios'
import store from '@/store'
import VueClipboard from 'vue-clipboard2'

Vue.use(VCalendar)
Vue.use(VModal)
Vue.use(VueClipboard)

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('schedule');
  axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
  axios.defaults.headers['X-CSRF-TOKEN'] = document
    .getElementsByName('csrf-token')[0]
    .getAttribute('content');
  new Vue({
    el: el,
    render: h => h(Schedule)
  })
  const el2 = document.getElementById('reservationCalendar');
  new Vue({
    el: el2,
    render: h => h(ReservationCalendar)
  })
  const el3 = document.getElementById('flowForm');
  new Vue({
    el: el3,
    store: store,
    render: h => h(FlowForm)
  })
  const el4 = document.getElementById('customerInfoForm');
  new Vue({
    el: el4,
    store: store,
    render: h => h(CustomerInfoForm)
  })
  const el5 = document.getElementById('reservationOpinionForm');
  new Vue({
    el: el5,
    store: store,
    render: h => h(ReservationOpinionForm)
  })
  const el6 = document.getElementById('propertyKsForm');
  new Vue({
    el: el6,
    store: store,
    render: h => h(PropertyKsForm)
  })
  const el7 = document.getElementById('roomReservationCalendar');
  new Vue({
    el: el7,
    store: store,
    render: h => h(ReservationCalendarByRoom)
  })
  const el8 = document.getElementById('guidanceWayForm');
  new Vue({
    el: el8,
    store: store,
    render: h => h(GuidanceWayForm)
  })
  const el9 = document.getElementById('takeFaceImageModal');
  new Vue({
    el: el9,
    store: store,
    render: h => h(TakeFaceImageModal)
  })
  const el10 = document.getElementById('takeCollatingPhoto');
  new Vue({
    el: el10,
    store: store,
    render: h => h(TakeFaceCollatingPhoto)
  })
})
