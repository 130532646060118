<template>
  <div>
    <keep-alive>
      <component :is="currentComponent"
        ref="currentComponent"
        @onStepSelected="onStepSelected" />
    </keep-alive>
  </div>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/company/Loading'
import SelectGuidanceWay from '@/components/company/SelectGuidanceWay'
import LocalGuidanceIntroduction from '@/components/company/LocalGuidanceIntroduction'
import RemoteGuidanceIntroduction from '@/components/company/RemoteGuidanceIntroduction'
export default {
  name: 'GuidaneWayForm',
  components: { Loading, SelectGuidanceWay, LocalGuidanceIntroduction, RemoteGuidanceIntroduction },
  data() {
    return {
      reservationToken: '',
      reservationId: '',
      reservation: null,
      guidanceWay: '',
      currentComponent: 'Loading',
    }
  },
  async mounted() {
    this.reservationToken = window.location.href.match(/reservations\/(.+)\/guidance_way/)[1]
    await this.fetchReservation()
    this.onStepSelected('top')
  },
  computed: {
  },
  methods: {
    async fetchReservation() {
      try {
        const res = await axios.get(`/api/company/reservations/${this.reservationToken}`)
        this.reservation = res.data.reservation
      } catch(e) {
        alert(e)
      }
    },
    async onStepSelected(step){
      if(step == 'top'){
        this.currentComponent = 'SelectGuidanceWay'
      }else if(step == 'local'){
        this.guidanceWay = 'local'
        this.currentComponent = 'LocalGuidanceIntroduction'
      }else if(step == 'remote'){
        this.guidanceWay = 'remote'
        this.currentComponent = 'RemoteGuidanceIntroduction'
      }else if(step == 'photo'){
        if(this.guidanceWay == 'remote'){
          await this.updateGuidanceWay()
          this.backToCalendar()
        }else if(this.guidanceWay == 'local'){
          await this.updateGuidanceWay()
          this.backToCalendar()
        }
      }
    },
    backToCalendar(){
      location.href = `/company/reservations/${this.reservation.id}/redirect_with_flash_message?action_type=guidance_way_create`
    },
    async updateGuidanceWay(){
      try {
        const res = await axios.patch(`/api/company/reservations/${this.reservationToken}/update_guidance_way`, { guidance_way: this.guidanceWay })
      } catch {
        alert('ERROR');
      }
    },
  },
  watch: {
  }
}
</script>

<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  height: 70px;
  width: 100%;
  background-color: white;
  border-top: 1px solid gray;
}
</style>

