<template>
  <div id='reservationCalendar'>
    <div class='card'>
    <div class='row col-md-12'>
      <div class='w100 mt-2'>
        <button @click="showModal" class='btn btn-info d-lg-block float-right'>
          <i class="fa fa-plus-circle mr-2"></i>新規登録
        </button>
        <form-modal :title="formData.title"
          :submitText="formData.submitText"
          :action="formData.action"
          :method="formData.method"
          :startAt="formData.startAt"
          :finishAt="formData.finishAt"
          :propertyId="formData.propertyId"
          :roomId="formData.roomId"
          :customerId="formData.customerId"
          :userId="formData.userId"
          :reservationId="formData.reservationId"
          />
      </div>
    </div>
    <div class='row'>
      <div class='col-md-8'>
        <div class='card-body'>
          <FullCalendar :options="calendarOptions"/>
        </div>
      </div>
      <div class='col-md-4'>
        <div class='card-body'>
          <h4 class="card-title m-t-10">{{dateForList}} の内見スケジュール</h4>
          <div class="row">
            <div class="col-md-12">
              <div class='card bg-light' v-for="rsvs in dailyReservations">
                <!-- 予約情報 -->
                <div class='card-body'>
                  <h5><i class="fas fa-home text-purple mr-2"></i>{{rsvs[0].room.name_with_property}}</h5>
                  <ul class='mb-5'>
                    <li v-for="rsv in rsvs">
                      {{startTime(rsv)}}~ {{rsv.customer.full_name}}様 / 担当: {{rsv.user.full_name}}<br />

                      <div class='mt-2' v-if="isRemote(rsv)">
                        <div class='mt-3'>
                          <!-- <button class='btn btn-sm btn-warning' @click="showGuidancePdfModal(rsv)"> -->
                          <!--   案内方法の確認 -->
                          <!-- </button> -->
                          <guidance-pdf-modal :rsv="rsv" />
                          <button class='btn btn-sm btn-secondary' @click="resetViewingWay(rsv)" v-show="isFutureDate(rsv.start_at)">
                            内見方法のリセット
                          </button>
                        </div>
                        <div class='mt-3'>
                          <a class='btn btn-sm btn-success' :href="naviUrl(rsv)" target='_blank'>仲介会社用ナビページ</a>
                        </div>
                        <div class="customerNaviUrlBox" style='position: relative;'>
                          <span class="box-title">お客様用URL</span>

                          <p>{{rsv.navi_url}}</p>
                          <button class='copy-btn'
                            v-clipboard:copy="rsv.navi_url"
                            v-clipboard:success="onCopySuccess"
                            v-clipboard:error="onCopyError">
                            <i class='fas fa-copy'></i>
                          </button>
                        </div>

                        <div class="customerNaviUrlBox" style='position: relative;'>
                          <span class="box-title">案内{{guidanceSendingStatusText(rsv)}}</span>
                          <div class='mt-2'>
                            <div class='mt-1'>
                              <a :href="sendSmsGuidanceUrl(rsv)"
                                class='btn btn-sm waves-effect waves-light btn-outline-secondary'
                                data-confirm="SMSを送信します。よろしいですか？"
                                v-if="smsSendable(rsv)">
                                <i class="fas fa-sms"></i>
                                SMSで案内送信
                              </a>
                            </div>
                            <div class='mt-1'>
                              <a :href="sendEmailGuidanceUrl(rsv)"
                                class='btn btn-sm waves-effect waves-light btn-outline-secondary'
                                data-confirm="メールを送信します。よろしいですか？"
                                v-if="emailSendable(rsv)">
                                <i class="far fa-envelope"></i>
                                メールで案内送信
                              </a>
                            </div>
                            <div class='mt-1'>
                              <button
                                class='btn btn-sm btn-rounded  waves-effect waves-light btn-outline-info'
                                v-if="smsSendable(rsv) || emailSendable(rsv)"
                                @click="showGuidanceText(rsv)">
                                <i class="fas fa-book-reader"></i>
                                文面確認
                              </button>
                              <guidance-text-modal
                                :title="guidanceText.title"
                                :text="guidanceText.text" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="isLocal(rsv)">
                        <div class='mt-3'>
                          <!-- <button class='btn btn-sm btn-warning' @click="showGuidancePdfModal(rsv)"> -->
                          <!--   案内方法の確認 -->
                          <!-- </button> -->
                          <guidance-pdf-modal :rsv="rsv" />
                          <button class='btn btn-sm btn-secondary' @click="resetViewingWay(rsv)"  v-show="isFutureDate(rsv.start_at)">
                            内見方法のリセット
                          </button>
                        </div>
                        <div class="customerNaviUrlBox" style='position: relative;'>
                          <span class="box-title">パスワード発行用URL</span>
                          <a :href="rsv.password_navi_url" style="color: #212529;">{{rsv.password_navi_url}}</a>
                          <button class='copy-btn'
                            v-clipboard:copy="rsv.password_navi_url"
                            v-clipboard:success="onCopySuccess"
                            v-clipboard:error="onCopyError">
                            <i class='fas fa-copy'></i>
                          </button>
                        </div>
                      </div>
                      <div v-if="!isRemote(rsv) && !isLocal(rsv)">
                        <div class='mt-3'>
                          <a class='btn btn-sm btn-success' :href="guidanceWayUrl(rsv)">案内方法の登録</a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class='card bg-light' v-if="dailyReservations.length == 0">
                <div class='card-body'>
                  <h5>内見の予約はありません</h5>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import formModal from '@/pages/company/reservations/form_modal'
import guidanceTextModal from '@/pages/company/reservations/guidance_text_modal'
import guidancePdfModal from '@/pages/company/reservations/guidance_pdf_modal'

export default {
  name: 'reservationCalenar',
  components: { FullCalendar, formModal, guidanceTextModal, guidancePdfModal },
  data() {
    return {
      formData: {
        title: '',
        submitText: '',
        action: '',
        method: '',
        startAt: '',
        finishAt: '',
        propertyId: '',
        roomId: '',
        customerId: '',
        userId: '',
        reservationId: '',
      },
      guidanceText: {
        title: '',
        text: '',
      },
      selectedDate: this.selectedDateFromParams(),
      dailyReservations: [],
      calendarOptions: {
        locale: 'ja',
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        selectable: true,
        dateClick: this.newDateClicked,
        eventClick: this.eventClicked,
        editable: true,
        initialDate: this.selectedDateFromParams(),
        validRange: {
          start: moment(new Date).subtract(1, 'months').format("YYYY-MM-DD"),
          end: moment(new Date).add(3, 'months').format("YYYY-MM-DD"),
        },
        headerToolbar: {
          left: 'title',
          center: '',
          right: 'prev next dayGridMonth,dayGridWeek,timeGridDay'
        },
        events: []
      }
    }
  },
  async created() {
    await this.fetchReservationsForCalendar()
    await this.fetchReservations(this.selectedDate)
  },
  computed: {
    dateForList() {
      return moment(this.selectedDate).format("YYYY年MM月DD日")
    },
    startTime: function(){
      return function(rsv){
        return moment(rsv.start_at).format('HH:mm')
      }
    },
    naviUrl: function(){
      return function(rsv){
        return `/company/reservations/${rsv.token}/navi`
      }
    },
    guidanceWayUrl: function(){
      return function(rsv){
        return `/company/reservations/${rsv.token}/guidance_way`
      }
    },
    smsSendable: function(){
      return function(rsv){
        return !(rsv.customer.tel == null)
      }
    },
    emailSendable: function(){
      return function(rsv){
        return !(rsv.customer.email == null)
      }
    },
    sendEmailGuidanceUrl: function(){
      return function(rsv){
        return `/company/reservations/${rsv.id}/send_guidance?way=email`
      }
    },
    sendSmsGuidanceUrl: function(){
      return function(rsv){
        return `/company/reservations/${rsv.id}/send_guidance?way=sms`
      }
    },
    guidanceSendingStatusText: function(){
      return function(rsv){
        return rsv.sent_guidance ? '(送信済)' : '(未送信)'
      }
    },
    isRemote: function(){
      return function(rsv){
        return rsv.guidance_way == 'remote'
      }
    },
    isLocal: function(){
      return function(rsv){
        return rsv.guidance_way == 'local'
      }
    },
  },
  methods: {
    async fetchReservationsForCalendar() {
      try {
        const res = await axios.get(`/api/reservations/for_calendar`);
        this.calendarOptions['events'] = res.data.reservations
      } catch {
        alert('ERROR');
      }
    },
    async fetchReservations(date) {
      try {
        const res = await axios.get(`/api/reservations`,
          { params: { date: date } });
        var result = res.data.reservations
        var dailyRsvs = []
        res.data.reservations.map(function(i, j) {
          var cat=i["room"]["room_id"]
          if(typeof dailyRsvs[cat]=="undefined") dailyRsvs[cat]=[]
          dailyRsvs[cat].push(i)
        })
        this.dailyReservations = dailyRsvs.filter(v => v)
      } catch {
        alert('ERROR');
      }
    },
    async resetViewingWay(rsv) {
      let result = window.confirm('内見方法の選択をリセットします。よろしいですか？')
      if(result){
        try {
          const res = await axios.post(`/api/company/reservations/${rsv.token}/reset_viewing_way`);
          this.fetchReservations(this.selectedDate)
        } catch {
          alert('ERROR');
        }
      }
    },
    selectedDateFromParams() {
      return !(this.getParam('selectedDate') == null) ? moment(this.getParam('selectedDate')).format("YYYY-MM-DD") : moment(new Date).format("YYYY-MM-DD")
    },
    newDateClicked(val) {
      this.selectedDate = val.dateStr
      this.fetchReservations(this.selectedDate)
    },
    eventClicked(val) {
      this.formData.title = '内見予約 編集'
      this.formData.submitText = '更新'
      this.formData.action = `/company/reservations/${val.event.id}`
      this.formData.method = 'patch'
      this.formData.startAt = String(moment(val.event.start).format('YYYY-MM-DD HH:mm'))
      this.formData.finishAt = String(val.event.extendedProps.finish)
      this.formData.propertyId = String(val.event.extendedProps.property.id)
      this.formData.roomId = String(val.event.extendedProps.room.id)
      this.formData.customerId = String(val.event.extendedProps.customer.id)
      this.formData.userId = String(val.event.extendedProps.user.id)
      this.formData.reservationId = String(val.event.id)
      this.$modal.show('reservation-form-modal')
    },
    showModal() {
      this.formData.title = '内見予約 新規登録'
      this.formData.submitText = '登録'
      this.formData.action = '/company/reservations'
      this.formData.method = 'post'
      this.formData.startAt = '',
      this.formData.finishAt =  '',
      this.formData.propertyId =  '',
      this.formData.roomId =  '',
      this.formData.customerId =  '',
      this.formData.userId = '',
      this.formData.reservationId = '',
      this.$modal.show('reservation-form-modal')
    },
    async showGuidanceText(rsv){
      this.guidanceText.title = `${this.startTime(rsv)}~ ${rsv.customer.full_name}様`
      await this.fetchGuidanceText(rsv)
      this.$modal.show('guidance-text-modal')
    },
    async showGuidancePdfModal(rsv){
      this.$modal.show('guidance-pdf-modal')
    },
    hideGuidanceText(){
      this.$modal.hide('guidance-text-modal')
    },
    async fetchGuidanceText(rsv) {
      try {
        const res = await axios.get(`/api/company/reservations/${rsv.token}/fetch_guidance_text`)
        this.guidanceText.text = res.data.text.trim()
      } catch {
        alert('ERROR');
      }
    },
    hideModal() {
      this.$modal.hide('reservation-form-modal');
    },
    onCopySuccess(e) {
      alert("URLをコピーしました")
    },
    onCopyError(e) {
      alert("URLのコピーに失敗しました")
    },
    getParam(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    isFutureDate(date){
      return moment(date) > moment()
    },
  }
}
</script>

<style scoped>
.customerNaviUrlBox {
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 25px 10px 7px;
    border: solid 1px #333333;
}
.customerNaviUrlBox .box-title {
    position: absolute;
    display: inline-block;
    top: -2px;
    left: -2px;
    padding: 0 9px;
    height: 25px;
    line-height: 25px;
    font-size: 1em;
    background: #444;
    color: #ffffff;
    transparent: 0.6;
}
.customerNaviUrlBox p {
    margin: 0;
    padding: 0;
}
.passwordBox {
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 25px 10px 7px;
    border: solid 1px #01c0c8;
}
.passwordBox .box-title {
    position: absolute;
    display: inline-block;
    top: -2px;
    left: -2px;
    padding: 0 9px;
    height: 25px;
    line-height: 25px;
    font-size: 1em;
    background: #01c0c8;
    color: #ffffff;
    transparent: 0.6;
}
.passwordBox p {
    margin: 0;
    padding: 0;
}
.copy-btn {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: none;
}
.copy-btn:active {
  border: none;
}
.text-color {
  color: #6c757d;
}

</style>
