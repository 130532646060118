<template>
  <div>
    <div>
      <div class="alert alert-danger alert-rounded" v-if="!errorMsg == ''">
        {{ errorMsg }}
      </div>
      <div v-show="shooting">
        <div class='row' v-show="capturedUrl == ''">
          <div class='col-12 text-center' id='faceCamera'>
            <video ref="video" id="video" :width='width' :height='height' autoplay>
            </video>
            <img src="~face_size_image.png" :width='width' :height='height' id="faceSizeImage">
          </div>
          <div class='col-12 text-center'>
            <a href="#" id="camera-icon" @click="capture()" class="btn btn--circle btn--circle-a btn--shadow">
              <i class="fas fa-camera fs32"></i>
            </a>

          </div>
        </div>

        <div class='row' v-show="!capturedUrl == ''">
          <div class='col-12 text-center'>
            <img :src="capturedUrl"/>
          </div>
        </div>
        <div class='row pl-3 pr-3 mt-3' v-show="!capturedUrl == ''">
          <div class='col-6 text-center mt-3'>
            <button class='btn btn-block btn-rounded btn-secondary' @click="retake">取り直し</button>
          </div>
          <div class='col-6 text-center mt-3'>
            <button class='btn btn-block btn-rounded btn-success' @click="save">決定</button>
          </div>
        </div>

        <div id='canvas_wrapper'>
          <canvas ref="canvas" id="canvas" :width="width" :height="height"></canvas>
        </div>
      </div>
      <Loading v-show="!shooting"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/company/Loading';
export default {
  name: 'TakeFaceImage',
  components: { Loading },
  data() {
    return {
      video: {},
      canvas: {},
      capturedUrl: '',
      userId: '',
      errorMsg: '',
      shooting: true,
      width: 0,
      height: 0
    }
  },
  beforeMount() {
    if(this.isSmartPhone()){
      this.width = 300
      this.height = 180
    }else{
      this.width = 500
      this.height = 300
    }
  },
  async mounted() {
    this.video = this.$refs.video
    this.userId = $('body').data('user-id')
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
        this.video.srcObject = stream
        this.video.play()
      })
    }
  },
  computed: {
  },
  methods: {
    isSmartPhone() {
      if (window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches) {
        return true;
      } else {
        return false;
      }
    },
    capture () {
      this.canvas = this.$refs.canvas
      this.canvas.getContext('2d').drawImage(this.video, 0, 0, this.width, this.height)
      this.capturedUrl = this.canvas.toDataURL('image/jpeg')
    },
    retake() {
      this.capturedUrl = ''
    },
    async save(){
      this.shooting = false
      try {
        const res = await axios.patch(`/api/company/users/${this.userId}/save_face_image`, {
          face_image: this.capturedUrl,
        })
        if(res.data.error == ''){
          location.href = `/company/users/${this.userId}/redirect_with_flash_message?action_type=take_face_image`
        }else{
          this.capturedUrl = ''
          this.errorMsg = res.data.error
          this.shooting = true
          setTimeout(() => {
            this.errorMsg = '' }
            , 3000
          )
        }
      } catch {
        this.capturedUrl = ''
        this.errorMsg = '登録時にエラーが発生しました。お手数ですが、運営事務局までお問い合わせください。'
        this.shooting = true
        setTimeout(() => {
          this.errorMsg = '' }
          , 3000
        )
      }
    },
    isSmartPhone() {
      if (window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches) {
        return true;
      } else {
        return false;
      }
    },
    },
  watch: {
  }
}
</script>

<style scope>
#canvas {
  display: none;
}
.capture {
  /* display: inline; */
  padding: 5px;
}
a.btn--orange {
  color: #fff;
  background-color: #eb6100;
}
a.btn--orange:hover {
  color: #fff;
  background: #f56500;
}
a.btn--shadow {
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}
a.btn--circle {
  border-radius: 50%;
  line-height: 90px;
  width: 75px;
  height: 75px;
  padding: 0;
}
video {
  object-fit: fill;
}
#faceCamera {
  position: relative;
}
#faceSizeImage {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
