<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'FetchLocation',
  data() {
    return {
      location: {
        lat: '',
        lon: '',
        accuracy: '',
        errorCode: '',
        errorMsg: '',
      }
    }
  },
  mounted() {
    this.getLocation()
  },
  methods: {
    getLocation() {
      // 端末が位置情報に対応している場合
      if(navigator.geolocation){
        navigator.geolocation.getCurrentPosition(
          function(position){
            let coords = position.coords
            this.location['lat'] = coords.latitude
            this.location['lon'] = coords.longitude
            this.location['accuracy'] = coords.accuracy
            this.$emit('setLocation', this.location)
          }.bind(this),
          function(e) {
            switch(e.code){
              case 0:
                this.location['errorCode'] = 0
                this.location['errorMsg'] = '原因不明のエラーが発生しました。お手数ですが、再度位置情報の取得をお試しください'
                break;
              case 1:
                this.location['errorCode'] = 1
                this.location['errorMsg'] = '本人確認の為、位置情報の取得許可が必須となります。'
                break;
              case 2:
                this.location['errorCode'] = 2
                this.location['errorMsg'] = '電波状況が悪い為、位置情報の取得ができませんでした。お手数ですが、再度お試しください'
                break;
              case 3:
                this.location['errorCode'] = 3
                this.location['errorMsg'] = '電波状況が悪い為、位置情報の取得ができませんでした。お手数ですが、再度お試しください'
                break;
            }
            this.$emit('setLocation', this.location)
          }.bind(this),
          {enableHighAccuracy: true}
        );
      // 端末が位置情報取得に対応していない場合
      }else{
        alert('位置情報未対応')
      }
    }
  }
}
</script>

<style scoped>
</style>
