<template>
  <modal name="local-caution-modal" :scrollable="true" height="80%" width="80%">
    <div class="modal-header">
      <h4 class="card-title"></h4>
      <span @click='hide' style='cursor: pointer;'><i class="fas fa-times"></i></span>
    </div>
    <div class="modal-body">
      <h2 class='mt-5 mb-5'>ご案内方法を現地案内にします。 よろしいですか？</h2>
      <div class='row w80 m0a mt-4'>
        <div class='col-6 text-center pl-0'>
          <button class='btn btn-lg btn-rounded btn-secondary' @click="hide()">
            <i class="fas fa-backward"></i>
              いいえ
          </button>
        </div>
        <div class='col-6 text-center pr-0'>
          <button class='btn btn-lg btn-rounded btn-success' @click="onStepSelected('photo')">
            はい
            <i class="fas fa-forward"></i>
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import axios from 'axios';
export default {
  name: 'LocalCautionModal',
  components: {},
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    hide() {
      this.$modal.hide('local-caution-modal');
    },
    onStepSelected(step){
      this.hide()
      this.$emit('onStepSelected', step)
    }
  },
}
</script>

<style scoped>
.w80{
  width: 80% !important
}
.m0a{
  margin: 0 auto;
}
</style>
