<template>
  <modal name="take-face-image-modal" height="100%" width="90%" :clickToClose="false">
    <div class="modal-header" style='margin-top: 70px;'>
      <h4 class="card-title" style='margin: 0 auto;'>写真撮影</h4>
    </div>
    <div class="modal-body">
      <h4 class='text-center'>本システムをご利用いただく為に、まずはご本人様の顔写真を撮影してください</h4>
      <p class='text-center'>(今後、鍵パスワード発行時の認証用写真として使用させて頂きます)</p>
    </div>
    <take-face-image />
  </modal>
</template>

<script>
import axios from 'axios';
import TakeFaceImage from '@/components/company/TakeFaceImage'
export default {
  name: 'TakeFaceImageModal',
  components: { TakeFaceImage },
  data() {
    return {
    }
  },
  async mounted() {
    this.$modal.show('take-face-image-modal');
  },
  computed: {
  },
  methods: {
    hide() {
      this.$modal.hide('take-face-image-modal');
    },
  },
  watch: {
  }
}
</script>

<style scoped>
</style>

