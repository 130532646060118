<template>
  <div>
    <p>現在の内見フロー: <span class='fwb'>{{currentStepText}}</span></p>
    <a href=''
      class='btn btn-md btn-outline-success btn-rounded mt-3 mr-3'
      :class="identifyDisabledClass"
      @click.prevent="onIdentifyBtnClicked()"
      v-if="showIdentificationBtn">
      <i class="fas fa-id-badge"></i>
      {{identifyBtnText}}</a>
    <a href=''
      class='btn btn-md btn-outline-info btn-rounded mt-3'
      :class="callDisabledClass"
      @click.prevent="onCallBtnClicked()">
      <i class="fas fa-phone"></i>
      {{callBtnText}}</a>

    <div v-if="reservation.identification_count >= 3">
      <div class='row mt-4'>
        <p class='mr-3'>お客様画像</p>
        <img :src="reservation.customer.face_image" width="180">
      </div>

      <div class='row mt-4'>
        <p class='mr-3'>認証画像</p>
        <span v-for="(cPhoto, index) in reservation.customer.collating_photos" :key="cPhoto.url">
          <img :src="cPhoto.url" width="180">
        </span>
      </div>
    </div>

    <form class="m-t-40" role="form" accept-charset="UTF-8" @submit.prevent>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group m-b-20">
            <label for="">名前(姓)</label>
            <input class="form-control form-control without-shadow" v-model="customer.lastName" disabled='true'>
            <span class="bar"></span>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group m-b-20">
            <label for="">名前(名)</label>
            <input class="form-control form-control without-shadow" v-model="customer.firstName" disabled='true'>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group m-b-20">
            <label for="">性別</label>
            <select class='form-control widhout-shadow' v-model="customer.sex">
              <option value="male">男性</option>
              <option value="female">女性</option>
              <option value="others">その他</option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group m-b-20">
            <label for="">年齢層</label>
            <select class='form-control widhout-shadow' v-model="customer.ageRange">
              <option value="teenage">10代</option>
              <option value="twenties">20代</option>
              <option value="thirties">30代</option>
              <option value="fourties">40代</option>
              <option value="fifties">50代</option>
              <option value="sixties">60代</option>
              <option value="seventies">70代</option>
              <option value="eighties">80代</option>
            </select>
          </div>
        </div>
      </div>
      <div class='row'>
        <div class="col-md-4">
          <div class="form-group m-b-20">
            <label for="">家族構成</label>
            <input class="form-control form-control without-shadow" v-model="customer.familyStructure">
            <span class="bar"></span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group m-b-20">
            <label for="">入居者構成</label>
            <input class="form-control form-control without-shadow" v-model="customer.residentStructure">
            <span class="bar"></span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group m-b-20">
            <label for="">職業</label>
            <input class="form-control form-control without-shadow" v-model="customer.occupation">
            <span class="bar"></span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group m-b-20">
            <label>お住まいエリア<span class='fs075 text-color'>(エリア名まで選択必須)</span></label>
            <Select2 v-model="selectedLivingAreaPrefectureId"
              :options="livingAreaPrefectures"
              @change="onPrefectureChanged(selectedLivingAreaPrefectureId, 'livingArea')"
              placeholder='都道府県' />
            <span class="bar"></span>
          </div>
        </div>
        <div class="col-md-4" style='margin-top: 7px;'>
          <div class="form-group m-b-20">
            <label></label>
            <Select2 v-model="selectedLivingAreaCityGroupId"
              :options="livingAreaCityGroups"
              @change="onCityGroupChanged(selectedLivingAreaPrefectureId, selectedLivingAreaCityGroupId, 'livingArea')"
              placeholder='グループ' />
            <span class="bar"></span>
          </div>
        </div>
        <div class="col-md-4" style='margin-top: 7px;'>
          <div class="form-group m-b-20">
            <label style='margin-top: 7px;'></label>
            <Select2 v-model="customer.livingArea"
              :options="livingAreaCities"
              placeholder='エリア名' />
            <span class="bar"></span>
          </div>
        </div>
      </div>

      <div class='row'>
        <div class="col-md-4">
          <div class="form-group m-b-20">
            <label>職場の場所<span class='fs075 text-color'>(エリア名まで選択必須)</span></label>
            <Select2 v-model="selectedWorkPlaceAreaPrefectureId"
              :options="workPlaceAreaPrefectures"
              @change="onPrefectureChanged(selectedWorkPlaceAreaPrefectureId, 'workPlaceArea')"
              placeholder='都道府県' />
            <span class="bar"></span>
          </div>
        </div>
        <div class="col-md-4" style='margin-top: 7px;'>
          <div class="form-group m-b-20">
            <label></label>
            <Select2 v-model="selectedWorkPlaceAreaCityGroupId"
              :options="workPlaceAreaCityGroups"
              @change="onCityGroupChanged(selectedWorkPlaceAreaPrefectureId, selectedWorkPlaceAreaCityGroupId, 'workPlaceArea')"
              placeholder='グループ' />
            <span class="bar"></span>
          </div>
        </div>
        <div class="col-md-4" style='margin-top: 7px;'>
          <div class="form-group m-b-20">
            <label style='margin-top: 7px;'></label>
            <Select2 v-model="customer.workPlaceArea"
              :options="workPlaceAreaCities"
              placeholder='エリア名' />
            <span class="bar"></span>
          </div>
        </div>
      </div>

      <div class='row'>
        <div class="col-md-4">
          <div class="form-group m-b-20">
            <label>希望エリア<span class='fs075 text-color'>(エリア名まで選択必須)</span></label>
            <Select2 v-model="selectedDesiredAreaPrefectureId"
              :options="desiredAreaPrefectures"
              @change="onPrefectureChanged(selectedDesiredAreaPrefectureId, 'desiredArea')"
              placeholder='都道府県' />
            <span class="bar"></span>
          </div>
        </div>
        <div class="col-md-4" style='margin-top: 7px;'>
          <div class="form-group m-b-20">
            <label></label>
            <Select2 v-model="selectedDesiredAreaCityGroupId"
              :options="desiredAreaCityGroups"
              @change="onCityGroupChanged(selectedDesiredAreaPrefectureId, selectedDesiredAreaCityGroupId, 'desiredArea')"
              placeholder='グループ' />
            <span class="bar"></span>
          </div>
        </div>
        <div class="col-md-4" style='margin-top: 7px;'>
          <div class="form-group m-b-20">
            <label style='margin-top: 7px;'></label>
            <Select2 v-model="customer.desiredArea"
              :options="desiredAreaCities"
              placeholder='エリア名' />
            <span class="bar"></span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <div class="form-group m-b-20">
            <label for="">希望入居時期(以降)</label>
            <select class='form-control widhout-shadow' v-model="customer.desiredDate">
              <option value="soon">今すぐ</option>
              <option value="in_one_month">1ヶ月以内</option>
              <option value="in_three_monthes">3ヶ月以内</option>
              <option value="in_six_monthes">半年以内</option>
              <option value="not_decided">決めていない</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group m-b-20">
            <label for="">希望家賃(万円以下)</label>
            <input class="form-control form-control without-shadow" type="number" step="0.1" v-model="customer.desiredRent">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group m-b-20">
            <label for="">希望間取り(以上)</label>
            <select class='form-control widhout-shadow' v-model="customer.desiredLayout">
              <option value="one_r">1R</option>
              <option value="one_k">1K</option>
              <option value="one_dk">1DK</option>
              <option value="one_ldk">1LDK</option>
              <option value="two_k">2K</option>
              <option value="two_dk">2DK</option>
              <option value="two_ldk">2LDK</option>
              <option value="three_k">3K</option>
              <option value="three_dk">3DK</option>
              <option value="three_ldk">3LDK</option>
              <option value="four_k">4K</option>
              <option value="four_dk">4DK</option>
              <option value="four_ldk">4LDK</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group m-b-20">
            <label for="">入居予定人数(人)</label>
            <input type='number' step='1' class="form-control form-control without-shadow" v-model="customer.desiredNum">
            <span class="bar"></span>
          </div>
        </div>
      </div>
      <div class='row'>
        <div class="col-md-12">
          <div class="form-group m-b-5">
            <label for="">住み替えの理由</label>
            <textarea class="form-control form-control without-shadow" rows="2" v-model="customer.movingReason">
            </textarea>
          </div>
        </div>
      </div>
      <div class='row'>
        <div class="col-md-12">
          <div class="form-group m-b-5">
            <label for="">その他</label>
            <textarea class="form-control form-control without-shadow" rows="2" v-model="customer.others">
            </textarea>
            <span class="bar"></span>
          </div>
        </div>
      </div>
      <div class="alert alert-success alert-rounded" v-show="successFlashShowable">
        更新しました
      </div>
      <div class="alert alert-danger alert-rounded" v-show="failedFlashShowable">
        更新時にエラーが発生しました
      </div>
      <div class="row">
        <div class="form-group m-b-5 mt-2 w100">
          <button name="button" type="submit" class="btn btn-info d-lg-block float-right mr-2" @click="onFormSubmit()">
            <i class="fas fa-plus"></i>
            更新
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import Select2 from 'v-select2-component';
export default {
  name: 'CustomerInfoForm',
  components: { Select2 },
  data() {
    return {
      successFlashShowable: false,
      failedFlashShowable: false,
      livingAreaPrefectures: [],
      workPlaceAreaPrefectures: [],
      desiredAreaPrefectures: [],
      livingAreaCityGroups: [],
      workPlaceAreaCityGroups: [],
      desiredAreaCityGroups: [],
      livingAreaCities: [],
      desiredAreaCities: [],
      workPlaceAreaCities: [],
      selectedLivingAreaPrefectureId: '',
      selectedWorkPlaceAreaPrefectureId: '',
      selectedDesiredAreaPrefectureId: '',
      selectedLivingAreaCityGroupId: '',
      selectedWorkPlaceAreaCityGroupId: '',
      selectedDesiredAreaCityGroupId: '',
      customer: {
        id: '',
        lastName: '',
        firstName: '',
        sex: '',
        ageRange: '',
        familyStructure: '',
        residentStructure: '',
        livingArea: '',
        workPlaceArea: '',
        occupation: '',
        desiredDate: '',
        desiredArea: '',
        desiredRent: '',
        desiredLayout: '',
        desiredNum: '',
        movingReason: '',
        others: '',
      },
      reservation: '',
      timer: '',
    }
  },
  async mounted() {
    await this.fetchCustomerIdFromRsvToken()
    await this.fetchReservationFromToken()
    await this.fetchCustomer()
    await this.fetchPrefectures()
    await this.setAreas()
    this.timer = setInterval(this.fetchReservationFromToken, 5000)
  },
  computed: {
    identifyDisabledClass() {
      return this.reservation.identified ? 'disabled' : ''
    },
    callDisabledClass() {
      return this.reservation.call_finished ? 'disabled' : ''
    },
    identifyBtnText() {
      return this.reservation.identified ? '本人確認済み' : '本人確認を完了させる'
    },
    callBtnText() {
      return this.reservation.call_finished ? '通話済み' : '通話済みに変更する'
    },
    showIdentificationBtn() {
      if(this.reservation == ''){
        return false
      }else{
        return this.reservation.property.use_face_verification ? this.reservation.identification_count >= 3 : true
      }
    },
    currentStepText(){
      return this.reservation.current_flow_detail == null ? 'ステップ無し' : `ステップ${this.reservation.step} / ${this.reservation.current_flow_detail}`

    }
  },
  methods: {
    async onIdentifyBtnClicked(){
      const rsvToken = window.location.href.match(/reservations\/(.+)\/navi/)[1]
      const result =confirm('本人確認済みステータスに変更します。よろしいですか？')
      if(result){
        try {
          const res = await axios.patch(`/api/company/reservations/${rsvToken}/identify`)
          this.reservation.identified = res.data.identified
        } catch(e) {
          alert(e)
        }
      }
    },
    async onCallBtnClicked(){
      const rsvToken = window.location.href.match(/reservations\/(.+)\/navi/)[1]
      const result =confirm('通話済みステータスに変更します。よろしいですか？')
      if(result){
        try {
          const res = await axios.patch(`/api/company/reservations/${rsvToken}/call_finished`)
          this.reservation.call_finished = res.data.call_finished
        } catch(e) {
          alert(e)
        }
      }
    },
    async fetchReservationFromToken() {
      try {
        const rsvToken = window.location.href.match(/reservations\/(.+)\/navi/)[1]
        const res = await axios.get(`/api/company/reservations/${rsvToken}`)
        this.reservation = res.data.reservation
        console.log(this.reservation)
      } catch(e) {
        alert(e)
      }
    },
    async setAreas() {
      if(this.customer.livingArea > 0){
        this.livingAreaCityGroups = await this.fetchCityGroups(this.selectedLivingAreaPrefectureId)
        this.livingAreaCities = await this.fetchCities(this.selectedLivingAreaPrefectureId, this.selectedLivingAreaCityGroupId)
      }
      if(this.customer.workPlaceArea > 0){
        this.workPlaceAreaCityGroups = await this.fetchCityGroups(this.selectedWorkPlaceAreaPrefectureId)
        this.workPlaceAreaCities = await this.fetchCities(this.selectedWorkPlaceAreaPrefectureId, this.selectedWorkPlaceAreaCityGroupId)
      }
      if(this.customer.desiredArea > 0){
        this.desiredAreaCityGroups = await this.fetchCityGroups(this.selectedDesiredAreaPrefectureId)
        this.desiredAreaCities = await this.fetchCities(this.selectedDesiredAreaPrefectureId, this.selectedDesiredAreaCityGroupId)
      }
    },
    addSuccessFlash() {
      this.successFlashShowable = true
      setTimeout(() => {
        this.successFlashShowable = false }
        , 2000
      )
    },
    addFailedFlash() {
      this.failedFlashShowable = true
      setTimeout(() => {
        this.failedFlashShowable = false }
        , 2000
      )
    },
    async fetchCustomerIdFromRsvToken(){
      const rsvToken = window.location.href.match(/reservations\/(.+)\/navi/)[1]
      try {
        const res = await axios.get(`/api/company/reservations/${rsvToken}/fetch_customer_id`)
        this.customer.id = res.data.id
      } catch {
        alert('ERROR');
      }
    },
    async fetchCustomer() {
      try {
        const res = await axios.get(`/api/company/customers/${this.customer.id}`)
        this.customer.lastName = res.data.customer.last_name
        this.customer.firstName = res.data.customer.first_name
        this.customer.sex = res.data.customer.sex
        this.customer.ageRange = res.data.customer.age_range
        this.customer.familyStructure = res.data.customer.family_structure
        this.customer.residentStructure = res.data.customer.resident_structure
        this.customer.livingArea = res.data.customer.living_area_id
        this.customer.workPlaceArea = res.data.customer.work_place_area_id
        this.customer.occupation = res.data.customer.occupation
        this.customer.desiredDate = res.data.customer.desired_date
        this.customer.desiredRent = res.data.customer.desired_rent
        this.customer.desiredArea = res.data.customer.desired_area_id
        this.customer.desiredLayout = res.data.customer.desired_layout
        this.customer.desiredNum = res.data.customer.desired_num
        this.customer.movingReason = res.data.customer.moving_reason
        this.customer.others = res.data.customer.others
        this.selectedLivingAreaCityGroupId = res.data.customer.living_area_city_group_id
        this.selectedLivingAreaPrefectureId = res.data.customer.living_area_prefecture_id
        this.selectedWorkPlaceAreaCityGroupId = res.data.customer.work_place_area_city_group_id
        this.selectedWorkPlaceAreaPrefectureId = res.data.customer.work_place_area_prefecture_id
        this.selectedDesiredAreaCityGroupId = res.data.customer.desired_area_city_group_id
        this.selectedDesiredAreaPrefectureId = res.data.customer.desired_area_prefecture_id
      } catch {
        alert('ERROR');
      }
    },
    async fetchPrefectures(){
      try {
        const res = await axios.get(`/api/company/prefecture_masters`)
        this.livingAreaPrefectures = res.data.prefecture_masters
        this.workPlaceAreaPrefectures = res.data.prefecture_masters
        this.desiredAreaPrefectures = res.data.prefecture_masters
      } catch {
        alert('ERROR')
      }
    },
    async fetchCityGroups(prefectureId){
      try {
        const res = await axios.get(`/api/company/prefecture_masters/${prefectureId}/city_group_masters`)
        return res.data.city_group_masters
      } catch {
        alert('ERROR')
      }
    },
    async fetchCities(prefectureId, cityGroupId){
      try {
        const res = await axios.get(`/api/company/prefecture_masters/${prefectureId}/city_group_masters/${cityGroupId}/city_masters`)
        return res.data.city_masters
      } catch {
        alert('ERROR')
      }
    },
    async onPrefectureChanged(prefectureId, category) {
      if(category == 'livingArea'){
        const res = await this.fetchCityGroups(prefectureId)
        this.livingAreaCityGroups = res
        this.selectedLivingAreaCityGroupId = null
        this.customer.livingArea = null
      }else if(category == 'workPlaceArea'){
        const res = await this.fetchCityGroups(prefectureId)
        this.workPlaceAreaCityGroups = res
        this.selectedWorkPlaceAreaCityGroupId = null
        this.customer.workPlaceArea = null
      }else if(category == 'desiredArea'){
        const res = await this.fetchCityGroups(prefectureId)
        this.desiredAreaCityGroups = res
        this.selectedDesiredAreaCityGroupId = null
        this.customer.cityArea = null
      }
    },
    async onCityGroupChanged(prefectureId, cityGroupId, category) {
      if(category == 'livingArea'){
        const res = await this.fetchCities(prefectureId, cityGroupId)
        this.livingAreaCities = res
        this.customer.livingArea = null
      }else if(category == 'workPlaceArea'){
        const res = await this.fetchCities(prefectureId, cityGroupId)
        this.workPlaceAreaCities = res
        this.customer.workPlaceArea = null
      }else if(category == 'desiredArea'){
        const res = await this.fetchCities(prefectureId, cityGroupId)
        this.desiredAreaCities = res
        this.customer.cityArea = null
      }
    },
    async onFormSubmit() {
      const url = `/api/company/customers/${this.customer.id}`
      const res = await axios.patch(url,
        { customer:
          { last_name: this.customer.lastName,
            first_name: this.customer.firstName,
            sex: this.customer.sex,
            age_range: this.customer.ageRange,
            living_area_id: this.customer.livingArea,
            work_place_area_id: this.customer.workPlaceArea,
            occupation: this.customer.occupation,
            family_structure: this.customer.familyStructure,
            resident_structure: this.customer.residentStructure,
            desired_date: this.customer.desiredDate,
            desired_area_id: this.customer.desiredArea,
            desired_rent: this.customer.desiredRent,
            desired_layout: this.customer.desiredLayout,
            desired_num: this.customer.desiredNum,
            moving_reason: this.customer.movingReason,
            others: this.customer.others,
          }
        }
      )
      this.addSuccessFlash()
    },
  },
}
</script>

<style scoped>
</style>
