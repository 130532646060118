<template>
  <div>
    <form class="m-t-40" role="form" accept-charset="UTF-8" @submit.prevent>
      <div class="alert alert-success alert-rounded" v-show="successFlashShowable">
        更新しました
      </div>
      <div class="alert alert-danger alert-rounded" v-show="failedFlashShowable">
        {{errorMsg}}
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group m-b-40">
            <label for="">フォーマット選択</label>
            <select class='form-control widhout-shadow' :value="flowDetailFormat" @change="onFormatChanged($event.target.value)">
              <option value="">フォーマットを選択してください</option>
              <option v-for="f in formats" :value="f.value">{{f.text}}</option>
            </select>
          </div>
        </div>
      </div>
      <div>
        <div class="row" v-if="showTitleForm">
          <div class="col-md-8">
            <div class="form-group m-b-40">
              <label for="">タイトル</label>
              <input class="form-control form-control without-shadow"

              :value="flowDetailTitle"
              @input="onTitleChanged($event.target.value)">
              <span class="bar"></span>
            </div>
          </div>
        </div>
        <div class='row' v-if="showSubTitleForm">
          <div class="col-md-12">
            <div class="form-group m-b-40">
              <label for="">サブタイトル</label>
              <input class="form-control form-control without-shadow"
              :value="flowDetailSubTitle"
              @input="onSubTitleChanged($event.target.value)" >
              <span class="bar"></span>
            </div>
          </div>
        </div>
        <div class="row" v-if="showBtnTextForm">
          <div class="col-md-12">
            <div class="form-group m-b-40">
              <label for="">ボタンテキスト</label>
              <input class="form-control form-control without-shadow"
              :value="flowDetailText"
              @input="onTextChanged($event.target.value)" >
              <span class="bar"></span>
            </div>
          </div>
        </div>
        <div class="row" v-if="showDescriptionForm">
          <div class="col-md-12">
            <div class="form-group m-b-40">
              <label for="">説明文</label>
              <textarea class="form-control form-control without-shadow"
                rows="4"
                :value="flowDetailText"
                @input="onTextChanged($event.target.value)">
              </textarea>
              <span class="bar"></span>
            </div>
          </div>
        </div>
        <div class="row" v-if="showImageForm">
          <div class="col-md-6">
            <div class="form-group m-b-5">
              <label for="">画像1</label>
              <div class="input-group">
                <div class="custom-file">
                  <input class="custom-file-input" type="file" @change="onImage1Changed($event)">
                  <label class="custom-file-label" for="">Choose file</label>
                </div>
              </div>
              <div class='mt-3 imagePreview' v-if="image1Displayable" style='position: relative;'>
                <img class='img' :src="flowDetailImage1">
                <span @click='onImageDeleted(1)'><i class="fas fa-trash deleteBtn text-danger"></i></span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group m-b-5">
              <label for="">画像2</label>
              <div class="input-group">
                <div class="custom-file">
                  <input class="custom-file-input" type="file" @change="onImage2Changed($event)">
                  <label class="custom-file-label" for="">Choose file</label>
                </div>
              </div>
            </div>
            <div class='mt-3 imagePreview' v-if="image2Displayable">
              <img class='img' :src="flowDetailImage2">
              <span @click="onImageDeleted(2)"><i class="fas fa-trash deleteBtn text-danger" ></i></span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group m-b-5 mt-4 w100">
          <button name="button" type="submit" class="btn btn-info d-lg-block float-right" @click="onFormSubmit()">
            <i class="fas fa-plus"></i>
            登録
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
  name: 'FlowDetailForm',
  data() {
    return {
      formats: [],
      successFlashShowable: false,
      failedFlashShowable: false,
      showBasicForm: false,
      isCallForm: false,
      isPopulifeForm: false,
      errorMsg: '',
    }
  },
  computed: {
    ...mapGetters('flow', ['flowId']),
    ...mapGetters('flowDetail', ['flowDetailId', 'flowDetailTitle', 'flowDetailSubTitle', 'flowDetailText', 'flowDetailFormat', 'flowDetailImage1', 'flowDetailImage2']),
    image1Displayable() {
      return !(this.flowDetailImage1 == '' || this.flowDetailImage1 == null || this.flowDetailImage1 == 'deleted')
    },
    image2Displayable() {
      return !(this.flowDetailImage2 == '' || this.flowDetailImage2 == null || this.flowDetailImage2 == 'deleted')
    },
    showTitleForm() {
      return (this.showBasicForm && !this.isPopulifeForm)
    },
    showSubTitleForm() {
      return (this.showBasicForm && !this.isPopulifeForm)
    },
    showBtnTextForm() {
      return this.isCallForm
    },
    showDescriptionForm() {
      return (this.showBasicForm && !this.isCallForm)
    },
    showImageForm() {
      return (this.showBasicForm && !this.isCallForm)
    },
  },
  methods: {
    addSuccessFlash() {
      this.successFlashShowable = true
      setTimeout(() => {
        this.successFlashShowable = false }
        , 3000
      )
    },
    addFailedFlash(errorMsg) {
      this.errorMsg = errorMsg
      this.failedFlashShowable = true
      setTimeout(() => {
        this.failedFlashShowable = false }
        , 3000
      )
    },
    onTitleChanged(val) {
      this.$store.commit('flowDetail/setTitle', val)
    },
    onSubTitleChanged(val) {
      this.$store.commit('flowDetail/setSubTitle', val)
    },
    onTextChanged(val) {
      this.$store.commit('flowDetail/setText', val)
    },
    onFormatChanged(val) {
      this.$store.commit('flowDetail/setFormat', val)
      if (this.flowDetailFormat == 'basic'){
        this.showBasicForm = true
      }else{
        this.showBasicForm = false
      }
    },
    onImageDeleted(no){
      console.log(no)
      if(no == 1){
        this.$store.commit('flowDetail/setImage1', 'deleted')
      }else if(no == 2){
        this.$store.commit('flowDetail/setImage2', 'deleted')
      }
    },
    async onImage1Changed(event) {
      const files = event.target.files || event.dataTransfer.files
      const file = files[0]
      const isNoProblem = this.checkFile(file)
      if(isNoProblem){
        const base64Image = await this.getBase64(file)
        this.$store.commit('flowDetail/setImage1', base64Image)
      }
    },
    async onImage2Changed(event) {
      const files = event.target.files || event.dataTransfer.files
      const file = files[0]
      console.log(file)
      const isNoProblem = this.checkFile(file)
      if(isNoProblem){
        const base64Image = await this.getBase64(file)
        this.$store.commit('flowDetail/setImage2', base64Image)
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    checkFile(file) {
      let result = true
      const SIZE_LIMIT = 5000000 // 5MB
      // キャンセルしたら処理中断
      if (!file) {
        result = false
      }
      // jpeg か png 関連ファイル以外は受付けない
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        alert('jpeg、もしくはpng形式のファイルを使用してください')
        result = false
      }
      // 上限サイズより大きければ受付けない
      if (file.size > SIZE_LIMIT) {
        alert('ファイルサイズが5MBを超えています')
        result = false
      }
      return result
    },
    async fetchFlowDetailFormats(){
      try {
        const res = await axios.get(`/api/company/flows/${this.flowId}/flow_details/fetch_formats`);
        this.formats = res.data.formats
      } catch {
        alert('ERROR');
      }
    },
    async onFormSubmit() {
      if(this.flowId.length == 0){
        const url = `/api/company/flows/${this.flowId}/flow_details`
        const res = await axios.post(url,
          { flow_detail:
            { title: this.flowDetailTitle,
              sub_title: this.flowDetailSubTitle,
              text: this.flowDetailText,
              image1: this.flowDetailImage1,
              image2: this.flowDetailImage2,
            }}
        )
        this.$emit('save')
        if(res.data.error == ''){
          this.addSuccessFlash()
        }else{
          this.addFailedFlash(res.data.error)
        }
      } else {
        const url = `/api/company/flows/${this.flowId}/flow_details/${this.flowDetailId}`
        const res = await axios.patch(url,
          { flow_detail:
            { title: this.flowDetailTitle,
              sub_title: this.flowDetailSubTitle,
              text: this.flowDetailText,
              format: this.flowDetailFormat,
              image1: this.flowDetailImage1,
              image2: this.flowDetailImage2,
            }}
        )
        this.$emit('save')
        if(res.data.error == ''){
          this.addSuccessFlash()
        }else{
          this.addFailedFlash(res.data.error)
        }
      }
    },
  },
  watch: {
    flowId: function() {
      this.fetchFlowDetailFormats()
    },
    flowDetailFormat: function(newValue) {
      let basicFormatUsing = [
        'basic',
        'get_key_from_keybox',
        'get_key_from_shop',
        'get_key_from_populife',
        'return_key_to_keybox',
        'return_key_to_shop',
        'return_key_to_populife',
        'call']
      this.showBasicForm = basicFormatUsing.includes(newValue) ? true : false
      this.isCallForm = newValue == 'call'
      this.isPopulifeForm = (newValue == 'get_key_from_populife' || newValue == 'return_key_to_populife')
    }

  }
}
</script>

<style scoped>
.imagePreview {
  width: 250px;
  position: relative;
}
.img {
  width: 100%;
}
.deleteBtn {
  position:absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
</style>

