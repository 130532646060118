<template>
  <modal name="guidance-pdf-modal" :draggable="true" :resizable="true" :scrollable="true" height="80%" width="80%">
    <div class="modal-header">
      <h4 class="card-title">
        {{rsv.room.name_with_property}}  /  {{startTime(rsv)}}~ {{rsv.customer.full_name}}様 ご案内方法
      </h4>
      <span @click='hide' style='cursor: pointer;'><i class="fas fa-times"></i></span>
    </div>
    <div class="modal-body">
      <iframe :src="pdfUrl" width="100%" height="450px"></iframe>
    </div>
  </modal>
</template>

<script>
import moment from 'moment'
export default {
  name: 'GuidancePdfModal',
  props: ['rsv'],
  components: {},
  data() {
    return {
      pdfUrl: ''
    }
  },
  mounted() {
    if(this.rsv.guidance_way == 'local'){
      if(this.rsv.use_face_verification){
        // 現地案内用資料URL(顔認証ありver)をセット
        this.pdfUrl = 'https://s3-ap-northeast-1.amazonaws.com/cdn.aprico-media.com/resources/sample.pdf'
      }else{
        // 現地案内用資料URL(顔認証なしver)をセット
        this.pdfUrl = 'https://itsudemo-naiken-production.s3.ap-northeast-1.amazonaws.com/resources/how_to_local_guide_without_face_verification.pdf'
      }
    }else if(this.rsv.guidance_way == 'remote'){
      if(this.rsv.use_face_verification){
        // 遠隔案内用資料URL(顔認証ありver)をセット
        this.pdfUrl = 'https://s3-ap-northeast-1.amazonaws.com/cdn.aprico-media.com/resources/sample.pdf'
      }else{
        // 遠隔案内用資料URL(顔認証なしver)をセット
        this.pdfUrl = 'https://s3-ap-northeast-1.amazonaws.com/cdn.aprico-media.com/resources/sample.pdf'
      }
    }
  },
  data() {
    return {
      pdfUrl: '',
    }
  },
  computed: {
    startTime: function(){
      return function(rsv){
        return moment(rsv.start_at).format('HH:mm')
      }
    },
  },
  methods: {
    hide() {
      this.$modal.hide('guidance-pdf-modal');
    },
  },
}
</script>

<style scoped>
</style>

