import axios from 'axios'

const state = {
  id: null,
  fullName: null,
  tel: null,
}

const getters = {
  customerId: (state) => {
    return state.id
  },
  customerFullName: (state) => {
    return state.fullName
  },
  customerTel: (state) => {
    return state.tel
  },
}

const mutations = {
  setId(state, payload) {
    state.id = payload
  },
  setFullName(state, payload) {
    state.fullName = payload
  },
  setTel(state, payload) {
    state.tel = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}

