import axios from 'axios'

const state = {
  id: null,
  fullName: null,
  tel: null
}

const getters = {
  userId: (state) => {
    return state.id
  },
  userFullName: (state) => {
    return state.fullName
  },
  userTel: (state) => {
    return state.tel
  },
}

const mutations = {
  setId(state, payload) {
    state.id = payload
  },
  setFullName(state, payload) {
    state.fullName = payload
  },
  setTel(state, payload) {
    state.tel = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}

