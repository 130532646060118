import axios from 'axios'

const state = {
  id: null,
  name: null
}

const getters = {
  companyId: (state) => {
    return state.id
  },
  companyName: (state) => {
    return state.fullName
  },
}

const mutations = {
  setId(state, payload) {
    state.id = payload
  },
  setName(state, payload) {
    state.fullName = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}


