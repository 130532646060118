<template>
  <v-date-picker v-model="selectedDate" @input="dateChanged(selectedDate)"></v-date-picker>
</template>

<script>
import moment from "moment"
export default {
  name: 'calendar',
  data() {
    return {
      selectedDate: new Date(),
    }
  },
  computed: {
  },
  methods: {
    dateChanged(val) {
      const d = moment(val).format("YYYY-MM-DD")
      this.$emit('dateChanged', { selectedDate: d })
    }
  },
}
</script>

<style scoped>
</style>
