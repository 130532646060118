<template>
  <div>
    <form class="m-t-40" role="form" accept-charset="UTF-8" @submit.prevent>
      <div class='row'>
        <div class="col-md-12">
          <div class="form-group m-b-5">
            <label for="">お客様が気になった点</label>
            <textarea class="form-control form-control without-shadow" rows="4" v-model="form.concern">
            </textarea>
            <span class="bar"></span>
          </div>
        </div>
      </div>
      <div class='row'>
        <div class="col-md-12">
          <div class="form-group m-b-5">
            <label for="">お客様が気に入った点</label>
            <textarea class="form-control form-control without-shadow" rows="4" v-model="form.favorite">
            </textarea>
            <span class="bar"></span>
          </div>
        </div>
      </div>
      <div class='row'>
        <div class="col-md-12">
          <div class="form-group m-b-5">
            <label for="">部屋に関して確認したい事項</label>
            <textarea class="form-control form-control without-shadow" rows="4" v-model="form.aboutRoom">
            </textarea>
            <span class="bar"></span>
          </div>
        </div>
      </div>
      <div class='row'>
        <div class="col-md-12">
          <div class="form-group m-b-5">
            <label for="">共有部分に関して確認したい事項</label>
            <textarea class="form-control form-control without-shadow" rows="4" v-model="form.aboutSharedArea">
            </textarea>
            <span class="bar"></span>
          </div>
        </div>
      </div>
      <div class='row'>
        <div class="col-md-12">
          <div class="form-group m-b-5">
            <label for="">周辺環境に関して確認したい事項</label>
            <textarea class="form-control form-control without-shadow" rows="4" v-model="form.aboutEnvironment">
            </textarea>
            <span class="bar"></span>
          </div>
        </div>
      </div>
      <div class="alert alert-success alert-rounded" v-show="successFlashShowable">
        更新しました
      </div>
      <div class="alert alert-danger alert-rounded" v-show="failedFlashShowable">
        更新時にエラーが発生しました
      </div>
      <div class="row">
        <div class="form-group m-b-5 mt-2 w100">
          <button name="button" type="submit" class="btn btn-info d-lg-block float-right mr-2" @click="onFormSubmit()">
            <i class="fas fa-plus"></i>
            更新
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'ReservationOpinionForm',
  data() {
    return {
      successFlashShowable: false,
      failedFlashShowable: false,
      reservationToken: '',
      form: {
        id: '',
        concern: '',
        favorite: '',
        aboutRoom: '',
        aboutSharedArea: '',
        aboutEnvironment: '',
      }
    }
  },
  async mounted() {
    this.reservationToken = window.location.href.match(/reservations\/(.+)\/navi/)[1]
    this.fetchReservation()
  },
  computed: {
  },
  methods: {
    addSuccessFlash() {
      this.successFlashShowable = true
      setTimeout(() => {
        this.successFlashShowable = false }
        , 2000
      )
    },
    addFailedFlash() {
      this.failedFlashShowable = true
      setTimeout(() => {
        this.failedFlashShowable = false }
        , 2000
      )
    },
    async fetchReservation() {
      try {
        const res = await axios.get(`/api/company/reservations/${this.reservationToken}`)
        this.form.id = res.data.reservation.reservation_opinion.id
        this.form.concern = res.data.reservation.reservation_opinion.concern
        this.form.favorite = res.data.reservation.reservation_opinion.favorite
        this.form.aboutRoom = res.data.reservation.reservation_opinion.about_room
        this.form.aboutSharedArea = res.data.reservation.reservation_opinion.about_shared_area
        this.form.aboutEnvironment = res.data.reservation.reservation_opinion.about_environment
      } catch(e) {
        alert('ERROR');
      }
    },
    async onFormSubmit(){
      try {
        const res = await axios.patch(`/api/company/reservations/${this.reservationToken}/reservation_opinions/${this.form.id}`,
          { reservation_opinion:
            { concern: this.form.concern,
              favorite: this.form.favorite,
              about_room: this.form.aboutRoom,
              about_shared_area: this.form.aboutSharedArea,
              about_environment: this.form.aboutEnvironment
            }
          })
        this.addSuccessFlash()
      } catch {
        alert('ERROR');
      }
    },
  }
}
</script>

<style scoped>
</style>


