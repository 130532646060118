<template>
  <modal name="guidance-text-modal" :draggable="true" :resizable="true" :scrollable="true" height="80%" width="80%">
    <div class="modal-header">
      <h4 class="card-title">{{title}} 案内メール文</h4>
      <span @click='hide' style='cursor: pointer;'><i class="fas fa-times"></i></span>
    </div>
    <div class="modal-body">{{text}}</div>
  </modal>
</template>

<script>
import axios from 'axios';

export default {

  name: 'guidanceTextModal',
  components: {},
  props:  { title: String,
            text: String },
  data() {
    return {
      reservation: '',
    }
  },
  computed: {
  },
  methods: {
    hide() {
      this.$modal.hide('guidance-text-modal');
    },

  },
}
</script>

<style scoped>
.modal-body {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>


