<template>
  <modal name="location-incorrect-modal" :clickToClose=false height="80%" width="80%">
    <div class="modal-header">
      <h4 class="card-title">位置情報が正しくありません</h4>
    </div>
    <div class="modal-body">
      取得した位置情報と、鍵の設置位置が合致しません。
      <br />
      <br />
      鍵の設置場所まで移動した後、再度本画面を読み込み、顔認証を進めてください。
      <br />
    </div>
  </modal>
</template>

<script>
import axios from 'axios';
export default {
  name: 'LocationIncorrectModal',
  components: {},
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    hide() {
      this.$modal.hide('location-incorrect-modal');
    },
  },
}
</script>

<style scoped>
.w80{
  width: 80% !important
}
.m0a{
  margin: 0 auto;
}
</style>

