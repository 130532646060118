<template>
  <modal name="photo-untakeable-modal" :clickToClose=false height="80%" width="80%">
    <div class="modal-header">
      <h4 class="card-title">本人確認時の注意事項</h4>
    </div>
    <div class="modal-body">
      本サービスをご利用いただくためには、お使いのブラウザに対して<br />
      <strong>1.カメラの使用許可</strong><br />
      <strong>2.位置情報の取得許可</strong><br />
      を出していただく必要がございます。

      <br />
      <br />
      下記の手順を元に利用許可を設定いただいた後、再度本ページを読み込んでください。<br /><br />
      <div class="iframe-wrapper">
        <iframe src="https://www.knt.co.jp/ec/2017/hannan-walk/gps.pdf" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>


    </div>
  </modal>
</template>

<script>
import axios from 'axios';
export default {
  name: 'PhotoUntakeableModal',
  components: {},
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    hide() {
      this.$modal.hide('photo-untakeable-photo');
    },
  },
}
</script>

<style scoped>
.w80{
  width: 80% !important
}
.m0a{
  margin: 0 auto;
}
.iframe-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
