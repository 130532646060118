import axios from 'axios'

const state = {
  id: null,
  layoutImageUrl: null,
}

const getters = {
  roomId: (state) => {
    return state.id
  },
  roomLayoutImageUrl: (state) => {
    return state.layoutImageUrl
  },
}

const mutations = {
  setId(state, payload) {
    state.id = payload
  },
  setLayoutImageUrl(state, payload) {
    state.layoutImageUrl = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}




