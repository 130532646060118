import axios from 'axios'

const state = {
  id: null,
  notes: null,
}

const getters = {
  flowId: (state) => {
    return state.id
  },
  flowNotes: (state) => {
    return state.notes
  },
}

const mutations = {
  setId(state, payload) {
    state.id = payload
  },
  setNotes(state, payload) {
    state.notes = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}

