<template>

  <div id='schedule' class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="card bg-light">
      <div class="row no-gutters align-items-center">
        <div class="col-md-6">
          <div class="card-body">
            <div class="text-center">
              <calendar @dateChanged="fetchReservations($event)"></calendar>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="card-body bg-white mt-3 mb-3">
            <h5 class="card-title">{{ titleWithSelectedDate }}</h5>
            <div class="d-flex no-block m-b-20 m-t-30" v-for="rsv in reservations">
              <div class="p-r-15">
                <img :src="rsv.room.image_url" width='50px'>
              </div>
              <div>
                <h5 class="card-title m-b-5">
                  <div>
                    <span class="text-muted fs075">{{ reservationStartTime(rsv) }}~</span>
                    <span class='fs075'>{{rsv.room.name_with_property}}</span>
                  </div>
                  <div>
                    <a :href="customerDetailPageLink(rsv)" class="link">{{ fullName(rsv) }} 様</a>
                    <span class="text-muted fs075">(担当: {{ rsv.user.full_name }})</span>
                  </div>
                </h5>
              </div>
            </div>
            <div v-if="reservations.length == 0">
              内覧予定なし
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from "moment"
import Calendar from '@/components/Calendar.vue'
export default {
  name: 'pageCompanyHomeIndex',
  data() {
    return {
      reservations: [],
      selectedDate: moment(new Date).format("YYYY-MM-DD")
    }
  },
  components: { Calendar },
  async created() {
    await this.fetchReservations({ selectedDate: this.selectedDate })
  },
  computed: {
    customerDetailPageLink: function(){
      return function(rsv){
        return `/company/customers/${rsv.id}`
      }
    },
    reservationStartTime: function(){
      return function(rsv){
        return moment(rsv.start_at).format("HH:mm")
      }
    },
    titleWithSelectedDate() {
      return `${this.selectedDate}の内覧予定`
    },
  },
  methods: {
    async fetchReservations(event) {
      const date = event['selectedDate'] == 'Invalid date' ? this.selectedDate : event['selectedDate']
      try {
        const res = await axios.get(`/api/reservations`,
          { params: { date: date } });
        this.reservations = res.data.reservations;
      } catch {
        alert('ERROR');
      }
      this.selectedDate = date
    },
    fullName(rsv) {
      if(rsv.length == 0){
        return '-'
      }else{
        return rsv.customer.last_name + rsv.customer.first_name
      }
    }
  },

}
</script>

<style>
</style>

