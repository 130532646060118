<template>
  <div>
    <div v-show="shooting">
      <div class='row bgw' v-show="capturedUrl == ''">
        <div class="alert alert-danger alert-rounded w100" v-if="!errorMsg == ''">
          {{ errorMsg }}
        </div>
        <div id='faceCamera' class='text-center col-sm-12 col-md-4 col-md-offset-4 mt-4 video_wrapper'>
          <video ref="video" id="video" autoplay width="300" height="200"></video>
          <img src="~face_size_image.png" width='300' height='200' id="faceSizeImage">
        </div>
        <div class='col-12 text-center mb-4 mt-2' v-if="!showCautionModal">
          <a href="#" id="camera-icon" @click="capture()" class="btn btn--circle btn--circle-a btn--shadow">
            <i class="fas fa-camera fs28"></i>
          </a>
        </div>
      </div>
      <div class='row bgw' v-show="!capturedUrl == ''">
        <div class='col-sm-12 col-md-4 text-center mt-4 video_wrapper'>
          <img :src="capturedUrl" />
        </div>
      </div>
      <div class='row bgw pr-3 pl-3 pb-5 pt-3' v-show="!capturedUrl == ''">
        <div class='col-sm-12 col-md-6 text-center mt-3'>
          <button class='btn btn-block btn-rounded btn-secondary' @click="retake">取り直し</button>
        </div>
        <div class='col-sm-12 col-md-6 text-center mt-3'>
          <button class='btn btn-block btn-rounded btn-success' @click="save">決定</button>
        </div>
      </div>

      <div id='canvas_wrapper'>
        <canvas ref="canvas" id="canvas" width="300px" height="200px"></canvas>
      </div>

      <fetch-location @setLocation='setLocation' />
      <photo-untakeable-modal />
      <location-incorrect-modal />
    </div>
    <Loading v-show="!shooting"/>
  </div>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/company/Loading';
import FetchLocation from '@/components/FetchLocation'
import PhotoUntakeableModal from '@/components/PhotoUntakeableModal'
import LocationIncorrectModal from '@/components/LocationIncorrectModal'
export default {
  name: 'TakeCollatingPhoto',
  components: { FetchLocation, PhotoUntakeableModal, Loading, LocationIncorrectModal },
  data() {
    return {
      token: '',
      video: {},
      canvas: {},
      capturedUrl: '',
      errorMsg: '',
      showCautionModal: false,
      showLocationIncorrectModal: false,
      shooting: true,
      location: {
        lat: '',
        lon: '',
        accuracy: '',
        errorMsg: '',
        errorCode: '',
      }
    }
  },
  async mounted() {
    await this.setCamera()
    this.token =  window.location.href.match(/.+\/reservations\/(.+)\/take_collating_photo/)[1]
  },
  computed: {
  },
  methods: {
    async setLocation(locationData){
      this.location['lat'] = locationData['lat']
      this.location['lon'] = locationData['lon']
      this.location['accuracy'] = locationData['accuracy']
      this.location['errorMsg'] = locationData['errorMsg']
      this.location['errorCode'] = locationData['errorCode']
      if(this.location['errorCode'] == 1){
        this.showCautionModal = true
      }
      try {
        const res = await axios.post(`/api/company/reservations/${this.token}/collating_photos/check_location`, {
          location: this.location,
        })
      }catch{
        this.showLocationIncorrectModal = true
      }
    },
    async setCamera(){
      this.video = this.$refs.video
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        try {
          var stream = await navigator.mediaDevices.getUserMedia({ video: true })
          this.video.srcObject = stream
          this.video.play()
        } catch(e){
          if(e.name == 'NotAllowedError'){
            this.showCautionModal = true
          }
        }
      }else{
        alert('カメラ未対応の端末のため、本機能をご利用いただけません。')
      }
    },
    capture () {
      this.canvas = this.$refs.canvas
      this.canvas.getContext('2d').drawImage(this.video, 0, 0, 300, 200)
      this.capturedUrl = this.canvas.toDataURL('image/jpeg')
      setTimeout(function() {
        window.scroll(0, 10000);
      }, 1);
    },
    retake() {
      this.capturedUrl = ''
    },
    async save(){
      this.shooting = false
      try {
        const res = await axios.post(`/api/company/reservations/${this.token}/collating_photos`, {
          collating_photo: this.capturedUrl,
          lat: this.location['lat'],
          lon: this.location['lon'],
        })
        if(res.data.error == ''){
          location.href = `/company/reservations/${this.token}/issue_password`
        }else{
          location.href = `/company/reservations/${this.token}/take_collating_photo`
        }
      } catch(e) {
        this.capturedUrl = ''
        this.errorMsg = e.error
        this.shooting = true
        setTimeout(() => {
          this.errorMsg = '' }
          , 3000
        )
      }
    },
  },
  watch: {
    showCautionModal: function(newVal, oldVal){
      if(newVal){
        this.$modal.show('photo-untakeable-modal')
      }else{
        this.$modal.hide('photo-untakeable-modal')
      }
    },
    showLocationIncorrectModal: function(newVal, oldVal){
      if(newVal){
        this.$modal.show('location-incorrect-modal')
      }else{
        this.$modal.hide('location-incorrect--modal')
      }
    }
  }
}
</script>

<style scope>
#canvas {
  display: none;
}
.capture {
  /* display: inline; */
  padding: 5px;
}
a.btn--orange {
  color: #fff;
  background-color: #eb6100;
}
a.btn--orange:hover {
  color: #fff;
  background: #f56500;
}
a.btn--shadow {
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}
a.btn--circle {
  border-radius: 50%;
  line-height: 90px;
  width: 75px;
  height: 75px;
  padding: 0;
}
.bgw {
  background-color: white;
}
.video_wrapper {
  margin: 0 auto;
}
video {
  object-fit: fill;
}
#faceCamera {
  position: relative;
}
#faceSizeImage {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
