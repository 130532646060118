<template>
  <div>
    <div class="alert alert-success alert-rounded" v-show="successFlash">
      更新しました
    </div>
    <div class="alert alert-danger alert-rounded" v-show="failedFlash">
      更新時にエラーが発生しました
    </div>
    <div>
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb20">登録済みKEY STATION一覧</h4>
          <vue-good-table
            :columns="assignsTable.columns"
            :rows="assignsTable.rows"
            style-class="vgt-table"
            max-height="300px"
            @on-selected-rows-change="onAssignsSelectionChanged"
            :search-options="{ enabled: true, placeholder: '検索'}"
            :select-options="{ enabled: true, selectionText: '件選択中', clearSelectionText: '取り消し' }">
            <div slot="emptystate">
              登録済みのKEY STATIONが存在しません
            </div>
            <div slot="selected-row-actions">
              <button class='btn btn-sm btn-danger float-right' @click="onAssignCanceled()">登録解除</button>
            </div>
          </vue-good-table>
        </div>
      </div>
    </div>

    <div class='mt-4'>
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb20">利用可能KEY STATION一覧</h4>
          <vue-good-table
            :columns="availablesTable.columns"
            :rows="availablesTable.rows"
            style-class="vgt-table"
            max-height="300px"
            @on-selected-rows-change="onAvailablesSelectionChanged"
            :search-options="{ enabled: true, placeholder: '検索'}"
            :select-options="{ enabled: true, selectionText: '件選択中', clearSelectionText: '取り消し' }">
            <div slot="emptystate">
              利用可能なKEY STATIONが存在しません
            </div>
            <div slot="selected-row-actions">
              <button class='btn btn-info btn-sm float-right' @click="onAssigned()">登録</button>
            </div>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

export default {
  name: 'PropertyKsForm',
  components: { VueGoodTable },
  data() {
    return {
      propertyId: '',
      successFlash: false,
      failedFlash: false,
      assignsTable: {
        rows: [],
        columns: [{ label: '優先順位', field: 'priority'},
                  { label: '名前', field: 'name'},
                  { label: '住所', field: 'address'},
                  { label: '利用可能本数', field: 'available_slots_num'}],
        selected: [],
      },
      availablesTable: {
        rows: [],
        columns: [{ label: 'ID', field: 'id'},
                  { label: '名前', field: 'name'},
                  { label: '住所', field: 'address'},
                  { label: '利用可能本数', field: 'available_slots_num'}],
        selected: []
      }
    }
  },
  async mounted() {
    this.propertyId = window.location.href.match(/properties\/(.+)\/edit/)[1]
    await this.fetchAvailableKeyStations()
    await this.fetchAssignsKeyStations()
    var tables = document.getElementsByClassName('vgt-table')
    for(var i=0; i<tables.length; i++){
      tables[i].style.fontSize = "1em";
    }
  },
  computed: {
  },
  methods: {
    async fetchAvailableKeyStations() {
      try {
        const res = await axios.get(`/api/company/properties/${this.propertyId}/ks_key_stations/availables`);
        this.availablesTable.rows = res.data.ks_key_stations
      } catch {
        alert('ERROR');
      }
    },
    async fetchAssignsKeyStations() {
      try {
        const res = await axios.get(`/api/company/properties/${this.propertyId}/ks_key_stations/assigns`);
        this.assignsTable.rows = res.data.ks_key_stations
      } catch {
        alert('ERROR');
      }
    },
    onAssignsSelectionChanged(params) {
      if(params.length == 0){
        this.assignsTable.selected = []
      }else{
        this.assignsTable.selected = params.selectedRows.map(p => p.id)
      }
    },
    onAvailablesSelectionChanged(params) {
      if(params.length == 0){
        this.availablesTable.selected = []
      }else{
        this.availablesTable.selected = params.selectedRows.map(p => p.id)
      }
    },
    async onAssignCanceled() {
      try {
        const res = await axios.post(`/api/company/properties/${this.propertyId}/ks_key_stations/assign_canceled`, {
          ks_key_station_ids: this.assignsTable.selected,
          property_id: this.propertyId
        })
        this.addSuccessFlash()
        await this.fetchAvailableKeyStations()
        await this.fetchAssignsKeyStations()
      } catch {
        this.addFailedFlash()
      }
    },
    async onAssigned() {
      try {
        const res = await axios.post(`/api/company/properties/${this.propertyId}/ks_key_stations/assigned`, {
          ks_key_station_ids: this.availablesTable.selected,
          property_id: this.propertyId
        })
        this.addSuccessFlash()
        await this.fetchAvailableKeyStations()
        await this.fetchAssignsKeyStations()
      } catch {
        this.addFailedFlash()
      }
    },
    addSuccessFlash() {
      this.successFlash = true
      setTimeout(() => {
        this.successFlash = false }
        , 2000
      )
    },
    addFailedFlash() {
      this.failedFlash = true
      setTimeout(() => {
        this.failedFlash = false }
        , 2000
      )
    },

  }
}
</script>

<style scoped>
#vgt-table {
  font-size: 1em !important;
}

</style>



