<template>
  <modal name="reservation-form-modal" :scrollable="true" height="auto" width="80%" @opened="assignPropsToModel()">
    <div class="modal-header">
      <h4 class="card-title">{{title}}</h4>
      <span @click='hide' style='cursor: pointer;'><i class="fas fa-times"></i></span>
    </div>
    <div class="modal-body">
      <div class="alert alert-danger alert-rounded" v-if="requestFailed">
        {{ failedMsg }}
      </div>
      <div class="card">
        <div class="card-body">
          <div class="form-group m-b-5" v-if="this.form.id.length != 0">
            <div class='row'>
              <div class='w100 mt-2'>
                <button class='btn btn-danger d-lg-block float-right' @click="onDelete()">
                  <i class="fa fa-minus-circle mr-2"></i>削除
                </button>
              </div>
            </div>
          </div>
          <form class="floating-labels m-t-20" @submit.prevent="onSubmit()">
            <div class='row'>
              <div class='col-md-6'>
                <div class="form-group m-b-40">
                  <vue-ctk-date-time-picker
                    label="開始日時"
                    minuteInterval="30"
                    :noHeader=true
                    format="YYYY-MM-DD HH:mm"
                    :noButtonNow=true
                    v-model="form.start_at">
                  </vue-ctk-date-time-picker>
                </div>
              </div>
              <div class='col-md-6'>
                <div class="form-group m-b-40">
                  <vue-ctk-date-time-picker
                    label="終了日時"
                    minuteInterval="30"
                    :noHeader=true
                    format="YYYY-MM-DD HH:mm"
                    :noButtonNow=true
                    v-model="form.finish_at">
                  </vue-ctk-date-time-picker>
                </div>
              </div>
            </div>

            <div class='row'>
              <div class='col-md-6'>
                <div class="form-group m-b-40">
                  <Select2 v-model="form.property_id"
                    :options="properties"
                    placeholder='物件' />
                  <span class="bar"></span>
                  <span class='mt-1' style='font-size: 0.8em; color: gray;'>全ての物件を選択可能にするには、メニューより顔写真の撮影を行ってください。</span>
                </div>
              </div>
              <div class='col-md-6'>
                <div class="form-group m-b-40">
                  <select class="form-control p-0 without-shadow" v-model="form.room_id">
                    <option value=''>部屋</option>
                    <option v-for="room in rooms" :value="room.id">{{room.name}}</option>
                  </select><span class="bar"></span>
                </div>
              </div>
            </div>
            <div class='row'>
              <div class='col-md-4'>
                <div class="form-group" v-show="!selectCustomer">
                  <select class="form-control p-0 without-shadow" v-model="form.customer_id">
                    <option value=''>お客様を選択してください</option>
                    <option v-for="customer in customers" :value="customer.id">{{customer.full_name}}</option>
                  </select><span class="bar"></span>
                </div>
                <div class="form-group m-b-40" v-show="selectCustomer">
                  <div class="card" style='border: 1px solid #e9ecef;'>
                    <div class="card-body">
                      <div class='row m-t-30'>
                        <div class='col-md-6'>
                          <input class="form-control without-shadow"
                            type="text"
                            v-model="customer.last_name"
                            placeholder="姓">
                          <span class="bar"></span>
                        </div>
                        <div class='col-md-6'>
                          <input class="form-control without-shadow"
                            type="text"
                            v-model="customer.first_name"
                            placeholder="名">
                          <span class="bar"></span>
                        </div>
                      </div>
                      <div class='row m-t-30'>
                        <div class='col-md-12'>
                          <input class="form-control without-shadow"
                            type="text"
                            v-model="customer.email"
                            placeholder="メールアドレス">
                          <span class="bar"></span>
                        </div>
                      </div>
                      <div class='row m-t-30'>
                        <div class='col-md-12'>
                          <input class="form-control without-shadow"
                            type="text"
                            v-model="customer.tel"
                            placeholder="電話番号">
                          <span class="bar"></span>
                        </div>
                      </div>
                      <div class='row m-t-40 float-right'>
                        <button class='btn btn-sm btn-outline-primary'
                          :disabled="!customerSubmitable"
                          @click.prevent="onCustomerCreated">登録</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class='col-md-2' v-show="!selectCustomer">
                <button class='btn btn-sm btn-outline-secondary' @click.prevent="switchCustomerForm">情報入力</button>
              </div>
              <div class='col-md-2' v-show="selectCustomer">
                <button class='btn btn-sm btn-outline-secondary' @click.prevent="switchCustomerForm">リストから選択</button>
              </div>

              <div class='col-md-6'>
                <div class="form-group m-b-40">
                  <select class="form-control p-0 without-shadow" v-model="form.user_id">
                    <option value=''>担当者を選択してください</option>
                    <option v-for="user in users" :value="user.id">{{user.full_name}}</option>
                  </select><span class="bar"></span>
                </div>
              </div>
            </div>
            <div class="form-group m-b-5">
              <div class='row'>
                <div class='w100 mt-2'>
                  <button class='btn btn-info d-lg-block float-right' :disabled="!submitable">
                    <i class="fa fa-plus-circle mr-2"></i>{{submitText}}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div style='height: 100px;'></div>
  </modal>
</template>

<script>
import axios from 'axios';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import Select2 from 'v-select2-component';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

export default {

  name: 'formModal',
  components: { VueCtkDateTimePicker, Select2 },
  props:  { title: String,
            submitText: String,
            submitMethod: String,
            action: String,
            method: String,
            startAt: String,
            finishAt: String,
            propertyId: String,
            roomId: String,
            customerId: String,
            userId: String,
            reservationId: String },
  data() {
    return {
      users: [],
      customers: [],
      properties: [],
      rooms: [],
      requestFailed: false,
      failedMsg: '',
      selectCustomer: false,
      customer: {
        last_name: '',
        first_name: '',
        email: '',
        tel: '',
      },
      form: {
        start_at: '',
        finish_at: '',
        property_id: '',
        room_id: '',
        customer_id: '',
        user_id: '',
        id: '',
      }
    }
  },
  async mounted() {
    await this.fetchUsers()
    await this.fetchCustomers()
    await this.fetchProperties()
  },
  computed: {
    submitable() {
      return this.form.start_at.length > 0
        && this.form.finish_at.length > 0
        && this.form.property_id > 0
        && this.form.room_id > 0
        && this.form.customer_id > 0
        && this.form.user_id > 0
    },
    customerSubmitable() {
      return this.customer.last_name.length > 0
        && this.customer.first_name.length > 0
    },
  },
  methods: {
    async assignPropsToModel() {
      this.form.id = this.reservationId
      this.form.start_at = this.startAt
      this.form.finish_at = this.finishAt
      this.form.property_id = this.propertyId
      this.form.customer_id = this.customerId
      this.form.user_id = this.userId
      await this.fetchProperties()
      this.form.room_id = this.roomId
    },
    async fetchUsers() {
      try {
        const res = await axios.get(`/api/company/users`)
        this.users = res.data.users
      } catch {
        alert('ERROR');
      }
    },
    async fetchCustomers() {
      try {
        const res = await axios.get(`/api/company/customers`)
        this.customers = res.data.customers
      } catch {
        alert('ERROR');
      }
    },
    async fetchProperties() {
      try {
        const res = await axios.get(`/api/company/properties`);
        var ary = res.data.properties.map(function(val){ return { id: val.id, text: val.name, disabled: val.disabled } })
        ary.unshift({id: null, text: '物件を選択してください' })
        this.properties = ary
      } catch {
        alert('ERROR');
      }
    },
    async fetchRoomsFromProperty(propId) {
      try {
        const res = await axios.get(`/api/company/properties/${propId}/rooms`)
        this.rooms = res.data.rooms
      } catch {
        alert('ERROR');
      }
    },
    async saveReservation(){
      try {
        const res = await axios.post(`/api/company/reservations`)
        const result = res.data.result
      } catch {
        alert('ERROR');
      }
    },
    async saveCustomer(){
      try {
        const res = await axios.post(`/api/company/customers`, { customer: this.customer })
        const result = res.data.customer
        await this.fetchCustomers()
        this.form.customer_id = result.id
      } catch {
        alert('ERROR');
      }
    },
    async onCustomerCreated(){
      this.saveCustomer()
      this.customer.last_name = ""
      this.customer.first_name = ""
      this.customer.email = ""
      this.customer.tel = ""
      this.switchCustomerForm()
    },
    async onSubmit(event) {
      var res = ''
      try{
        if(this.form.id.length == 0){
          res = await axios.post(this.action, { reservation: this.form } )
          if(res.data.error_msg){
            this.requestFailed = true
            this.failedMsg = res.data.error_msg
            setTimeout(() => {
              this.requestFailed = false }
              , 3000
            )
          } else {
            location.href = `/company/reservations/${res.data.id}/redirect_with_flash_message?action_type=create`
          }
        } else {
          res = await axios.patch(this.action, { reservation: this.form } )
          if(res.data.error_msg){
            this.requestFailed = true
            this.failedMsg = res.data.error_msg
            setTimeout(() => {
              this.requestFailed = false }
              , 3000
            )
          } else {
            location.href = `/company/reservations/${res.data.id}/redirect_with_flash_message?action_type=update`
          }
        }
      } catch(e) {
        alert('ERROR')
      }
    },
    async onDelete(event) {
      var result = window.confirm("内見予約を削除します。よろしいですか？")
      if(result){
        const res = await axios.delete(this.action)
        location.href = `/company/reservations/${res.data.id}/redirect_with_flash_message?action_type=delete`
      }
    },
    switchCustomerForm() {
      this.selectCustomer = !this.selectCustomer
    },
    hide() {
      this.$modal.hide('reservation-form-modal');
    }
  },
  watch: {
    "form.property_id": function(newValue, oldValue){
      this.form.room_id = ""
      if(newValue > 0){
        this.fetchRoomsFromProperty(newValue)
      }else{
        this.rooms = []
      }
    }
  }
}
</script>

<style scoped>
</style>

